import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToolConfigurationRoutesConfig } from "../../navigation/config";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import {
  MetadataActiveAlert,
  AlertStatusEnum,
  AlertRequest,
  AlertDetailsViewModel,
  CountryViewModel,
} from "orval/generated/models";
import BannerAlert from "components/common/BannerAlert/BannerAlert";
import "./BannerAlertConfigurationPage.scss";
import { useGlobalLoader } from "components/common";
import { useModal } from "components/common/Modal";
import EnableBannerAlertModal from "./Modals/EnableBannerAlertModal";
import { AlignmentEnumType } from "./types";
import "dayjs/locale/en-gb";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import ConfirmationModal from "components/common/ConfirmationModal/ConfirmationModal";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  getGetAlertDetailsForAdminPanelAlertsGetQueryKey,
  getGetAppInfoMetadataGetQueryKey,
  useCreateAlertAlertsPost,
  useGetAlertDetailsForAdminPanelAlertsGet,
  useGetMemberCountriesMembersCountriesGet,
  useResetAlertAlertsAlertIdResetPost,
  useUpdateAlertStatusAlertsAlertIdPut,
} from "orval/generated/endpoint";

const BannerAlertConfigurationPage = () => {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { showGlobalLoader } = useGlobalLoader();
  const bannerId = 1;
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const [state, setState] = useState({
    previewBanner: {
      id: 1,
      message: t("alertConfigurationPage.messagePlaceholder"),
      type: "error",
    },
    alignment: "bannerOff" as AlignmentEnumType,
  });

  const {
    data: alertDetails,
    isPending: alertDetailsIsPending,
    error: alertDetailsIsError,
  } = useGetAlertDetailsForAdminPanelAlertsGet();

  const formik = useFormik<AlertRequest>({
    initialValues: {
      countries: alertDetails?.countries || [],
      end_date: alertDetails?.end_date || "",
      message: alertDetails?.message || "",
      start_date: alertDetails?.start_date || "",
      type: (alertDetails?.type as AlertStatusEnum) || "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      countries: yup
        .array()
        .min(1, t("alertConfigurationPage.validationErrors.atLeastOneCountry")),
      end_date: yup
        .date()
        .required(t("alertConfigurationPage.validationErrors.endDateRequired")),
      message: yup
        .string()
        .required(t("alertConfigurationPage.validationErrors.messageRequired")),
      start_date: yup
        .date()
        .required(
          t("alertConfigurationPage.validationErrors.startDateRequired"),
        ),
      type: yup
        .string()
        .required(t("alertConfigurationPage.validationErrors.typeRequired")),
    }),
    onSubmit: async (values) => {
      try {
        await createAlert({ data: values });
      } catch (error: any) {
        console.warn("Error creating alert", error);
      } finally {
        showGlobalLoader(false);
      }
    },
  });

  useEffect(() => {
    if (alertDetails) {
      setState((prevState) => ({
        ...prevState,
        alignment: alertDetails.active ? "bannerOn" : "bannerOff",
      }));
    }
  }, [alertDetails]);

  useEffect(() => {
    if (alertDetailsIsError) {
      showSnackbar(alertDetailsIsError as AxiosError<unknown, any>, "error");
    }
  }, [alertDetailsIsError, showSnackbar]);

  const { data: countriesForDropdown, error: countriesFetchError } =
    useGetMemberCountriesMembersCountriesGet();

  useEffect(() => {
    if (countriesFetchError) {
      showSnackbar(countriesFetchError as AxiosError<unknown, any>, "error");
    }
  }, [countriesFetchError, showSnackbar]);

  const handleAlignment = (
    _event: MouseEvent<HTMLElement>,
    newAlignment: AlignmentEnumType,
  ) => {
    setState((prevState) => ({
      ...prevState,
      alignment: newAlignment,
    }));
  };

  const { mutateAsync: createAlert, isPending: createAlertIsPending } =
    useCreateAlertAlertsPost({
      mutation: {
        onSuccess: async (_alert: AlertDetailsViewModel) => {
          showSnackbar(t("alertConfigurationPage.alertCreated"), "success");
          await queryClient.invalidateQueries({
            queryKey: getGetAlertDetailsForAdminPanelAlertsGetQueryKey(),
          });
        },
        onError: (error: AxiosError) => {
          console.warn(error);
          showSnackbar(error, "error");
        },
      },
    });

  const {
    mutateAsync: updateAlertStatus,
    isPending: updateAlertStatusIsPending,
  } = useUpdateAlertStatusAlertsAlertIdPut({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetAlertDetailsForAdminPanelAlertsGetQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getGetAppInfoMetadataGetQueryKey(),
        });
        closeModal();
      },
      onError: (error: AxiosError) => {
        showSnackbar(error, "error");
      },
    },
  });

  const handleTurnBannerOn = useCallback(() => {
    updateAlertStatus({
      alertId: bannerId.toString(),
      data: { enabled: true },
    }).then(() => {
      showSnackbar(
        t("alertConfigurationPage.bannerTurnedOnSuccessfully"),
        "success",
      );
    });
  }, [updateAlertStatus, showSnackbar, t]);

  const handleTurnBannerOff = useCallback(() => {
    updateAlertStatus({
      alertId: bannerId.toString(),
      data: { enabled: false },
    }).then(() => {
      showSnackbar(
        t("alertConfigurationPage.bannerTurnedOffSuccessfully"),
        "success",
      );
    });
  }, [updateAlertStatus, showSnackbar, t]);

  const { mutateAsync: resetAlert, isPending: resetAlertIsPending } =
    useResetAlertAlertsAlertIdResetPost({
      mutation: {
        onSuccess: async () => {
          showSnackbar(t("alertConfigurationPage.alertReset"), "success");
          await queryClient.invalidateQueries({
            queryKey: getGetAlertDetailsForAdminPanelAlertsGetQueryKey(),
          });
        },
        onError: (error: AxiosError) => {
          showSnackbar(error, "error");
        },
      },
    });

  const handleResetForm = useCallback(() => {
    formik.resetForm();
    closeModal();
  }, [formik, closeModal]);

  const handleOpenEnableBannerModal = useCallback(() => {
    openModal(
      <EnableBannerAlertModal
        closeModal={closeModal}
        handleTurnBannerOn={handleTurnBannerOn}
        previewBanner={state.previewBanner as MetadataActiveAlert}
        handleAlignment={handleAlignment}
      ></EnableBannerAlertModal>,
    );
  }, [openModal, closeModal, handleTurnBannerOn, state.previewBanner]);

  const handleOpenResetBannerModal = useCallback(() => {
    openModal(
      <ConfirmationModal
        title={t("alertConfigurationPage.resetAlertModal.title")}
        description={t("alertConfigurationPage.resetAlertModal.description")}
        actionTitle={t("common:actions.reset")}
        confirmAction={async () => {
          try {
            await resetAlert({ alertId: bannerId });
            closeModal();
          } catch (error) {
            showSnackbar(error as unknown as AxiosError<unknown, any>, "error");
            console.warn("Error resetting alert", error);
          } finally {
            handleResetForm();
          }
        }}
        cancelAction={closeModal}
      />,
      true,
    );
  }, [openModal, t, closeModal, resetAlert, showSnackbar, handleResetForm]);

  const handleOpenDisableBannerModal = useCallback(() => {
    openModal(
      <ConfirmationModal
        title={t("alertConfigurationPage.disableAlertModal.title")}
        description={t("alertConfigurationPage.disableAlertModal.description")}
        actionTitle={t("common:actions.disable")}
        confirmAction={(event?: React.MouseEvent<HTMLElement>) => {
          if (event) {
            handleTurnBannerOff();
            handleAlignment(event, "bannerOff");
            closeModal();
          }
        }}
        cancelAction={(event?: React.MouseEvent<HTMLElement>) => {
          if (event) {
            handleAlignment(event, "bannerOn");
            closeModal();
          }
        }}
      />,
      true,
    );
  }, [openModal, t, handleTurnBannerOff, closeModal]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      previewBanner: {
        id: 1,
        message:
          formik.values.message ||
          t("alertConfigurationPage.messagePlaceholder"),
        type: formik.values.type as AlertStatusEnum,
      },
    }));
  }, [formik.values.message, formik.values.type, t]);

  function handleSaveCountries(selectedItems: string[]) {
    const isoCountryCodes = selectedItems.map(
      (displayName) =>
        countriesForDropdown?.find(
          (country: CountryViewModel) => country.display_name === displayName,
        )?.country,
    );
    formik.setFieldValue("countries", isoCountryCodes);
  }

  function getSavedSelectedItemsForCountries(): string[] {
    return formik.values.countries
      .map(
        (countryCode) =>
          countriesForDropdown?.find(
            (country: CountryViewModel) => country.country === countryCode,
          )?.display_name,
      )
      .filter(
        (displayName): displayName is string => displayName !== undefined,
      );
  }

  useEffect(() => {
    alertDetailsIsPending ||
    updateAlertStatusIsPending ||
    createAlertIsPending ||
    resetAlertIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    showGlobalLoader,
    alertDetailsIsPending,
    updateAlertStatusIsPending,
    createAlertIsPending,
    resetAlertIsPending,
  ]);

  return (
    <>
      {alertDetails && countriesForDropdown ? (
        <Box>
          <Button
            variant="text"
            onClick={() =>
              navigate(
                `${ToolConfigurationRoutesConfig.toolConfigurationPage}?`,
              )
            }
            startIcon={<ArrowBackIosSharpIcon />}
          >
            {t("backButtonText")}
          </Button>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography className={"header-H2"} fontWeight="500">
                {t("alertConfigurationPage.title")}
              </Typography>
            </Box>
            <Box>
              <ToggleButtonGroup
                value={state.alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                data-testid="toggler"
              >
                <ToggleButton
                  value="bannerOn"
                  aria-label={t("alertConfigurationPage.bannerOn")}
                  className="banner-config-toggle"
                  onClick={handleOpenEnableBannerModal}
                  disabled={
                    !alertDetails?.start_date ||
                    alertDetails?.active ||
                    formik.dirty ||
                    state.alignment === "bannerOn"
                  }
                  data-testid="toggler-on-btn"
                >
                  {t("alertConfigurationPage.on")}
                </ToggleButton>
                <ToggleButton
                  value="bannerOff"
                  aria-label={t("alertConfigurationPage.bannerOff")}
                  className="banner-config-toggle"
                  onClick={handleOpenDisableBannerModal}
                  disabled={
                    !alertDetails?.active || state.alignment === "bannerOff"
                  }
                  data-testid="toggler-off-btn"
                >
                  {t("alertConfigurationPage.off")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              {t("alertConfigurationPage.description")}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className="header-H3">
              {t("alertConfigurationPage.customiseAlert")}
            </Typography>
          </Box>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="alert-type">
                  {t("alertConfigurationPage.formHeaders.type")}
                </InputLabel>
                <Select
                  labelId="alert-type-label-id"
                  id="alert-type-simple-select"
                  name="type"
                  value={formik.values.type}
                  label={t("alertConfigurationPage.formHeaders.type")}
                  onChange={formik.handleChange}
                  disabled={alertDetails?.active}
                  variant="outlined"
                >
                  {Object.values(AlertStatusEnum)
                    .sort()
                    .map((type) => (
                      <MenuItem key={type} value={type}>
                        {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
                      </MenuItem>
                    ))
                    .filter((type) => type.key !== "")}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <SelectDropdown
                listItems={
                  countriesForDropdown?.map(
                    (country: CountryViewModel) => country.display_name,
                  ) ?? []
                }
                onSave={handleSaveCountries}
                savedSelectedItems={getSavedSelectedItemsForCountries()}
                title={t("alertConfigurationPage.formHeaders.countries")}
                selectAll={true}
                compact
                disabled={alertDetails?.active}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size={"small"}>
                <TextField
                  id="outlined-multiline-static"
                  name="message"
                  label={"Message"}
                  multiline
                  rows={3}
                  disabled={alertDetails?.active}
                  inputProps={{ maxLength: 250 }}
                  onChange={formik.handleChange}
                  placeholder={t("alertConfigurationPage.messagePlaceholder")}
                  value={
                    formik.values.message ??
                    t("alertConfigurationPage.messagePlaceholder")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header-H4 preview-banner-header">
                {t("alertConfigurationPage.formHeaders.preview")}
              </Typography>
              {state.previewBanner ? (
                <BannerAlert
                  activeAlert={state.previewBanner as MetadataActiveAlert}
                  preview={true}
                />
              ) : (
                <Typography variant="body1">
                  {t("alertConfigurationPage.previewUnavailable")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography className="header-H3">
                  {t("alertConfigurationPage.scheduleAlert")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label={t("alertConfigurationPage.formHeaders.from")}
                  value={
                    formik.values.start_date
                      ? dayjs(formik.values.start_date)
                      : null
                  }
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      "start_date",
                      newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
                    );
                  }}
                  sx={{ width: "100%" }}
                  minDate={dayjs()}
                  maxDate={dayjs(formik.values.end_date)}
                  disabled={alertDetails?.active}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label={t("alertConfigurationPage.formHeaders.to")}
                  value={
                    formik.values.end_date
                      ? dayjs(formik.values.end_date)
                      : null
                  }
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      "end_date",
                      newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
                    );
                  }}
                  sx={{ width: "100%" }}
                  minDate={dayjs(formik.values.start_date) || dayjs()}
                  disabled={alertDetails?.active}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems="start"
            justifyContent="space-between"
            mt={2}
            mb={3}
          >
            {formik.errors ? (
              <Box
                mt={2}
                mr={4}
                color="error.main"
                flexGrow={3}
                sx={{
                  transition: "max-height 2s ease-in-out",
                  overflow: "hidden",
                  maxHeight: formik.errors ? "500px" : "0",
                }}
              >
                {Object.values(formik.errors).map((error, index) => (
                  <Box mb={1} key={index}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box></Box>
            )}
            <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
              <Box mr={2}>
                <Button
                  variant="outlined"
                  onClick={handleOpenResetBannerModal}
                  disabled={
                    !Boolean(alertDetails?.start_date) ||
                    Boolean(alertDetails?.active)
                  }
                >
                  {t("common:actions.reset")}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  disabled={
                    !formik.isValid ||
                    !formik.dirty ||
                    Boolean(alertDetails?.active)
                  }
                >
                  {t("common:actions.save")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default BannerAlertConfigurationPage;
