import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../../../MicroAdjustmentsTable/types";

export const LogisticsTableMetrics: ProductsEditableColumnConfigItem[] = [
  {
    field_key: "primary_road",
    label: "micro:logisticsSection.table.primary_road",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "secondary_road",
    label: "micro:logisticsSection.table.secondary_road",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "ship",
    label: "micro:logisticsSection.table.ship",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "train",
    label: "micro:logisticsSection.table.train",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "plane",
    label: "micro:logisticsSection.table.plane",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
];
