import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useModal } from "components/common/Modal";
import ReportIssueModalContent from "./ReportIssueModalContent";

export default function ReportIssue() {
  const { t } = useTranslation("common");
  const { openModal } = useModal();

  return (
    <Tooltip title={t("reportIssue.toolTip")}>
      <IconButton
        onClick={() => {
          openModal(<ReportIssueModalContent />, true);
        }}
        size="small"
        sx={{ ml: 2 }}
        data-testid="report-icon-btn"
      >
        <ErrorIcon sx={{ color: "#d04a02", fontSize: 45 }} />
      </IconButton>
    </Tooltip>
  );
}
