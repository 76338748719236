/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Product Emissions Forecaster
 * OpenAPI spec version: 3.1
 */

export type UserDataTypes = (typeof UserDataTypes)[keyof typeof UserDataTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDataTypes = {
  percentage: "percentage",
  new_value: "new_value",
} as const;
