import "./MicroResultsTable.scss";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { MicroTableWrapper } from "components/common";
import { formatNumberOrEmpty } from "utils/formatNumber/formatNumber";
import { COLUMN_WIDTH, ROW_ACTION_COLUMN_WIDTH } from "../../constants";
import { AppConstant } from "constants/AppConstant";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import { ProductConfigurationsMicro } from "../MicroAdjustmentsTable/types";

export type MicroResultsTableProps = {
  columns: MRT_ColumnDef<any>[];
  data: any;
};
export const MicroResultsTable = ({
  columns,
  data,
}: MicroResultsTableProps) => {
  const columns_ = columns.map((column) => {
    if (column.id === "metric")
      return {
        ...column,
        size: COLUMN_WIDTH - ROW_ACTION_COLUMN_WIDTH,
      };
    return column;
  });
  const { palette } = useTheme();
  const { t } = useTranslation("micro");

  const data_: { [key: string]: string }[] = data.map(
    (item: { [key: string]: string }) => {
      const newItem = { ...item };

      Object.keys(newItem)
        .filter(
          (key) =>
            !key.includes("metric") &&
            !key.includes("subRows") &&
            !key.includes("unit"),
        )
        .forEach((key) => {
          if (item[key] === AppConstant.emptyCell) {
            newItem[key] = AppConstant.emptyCell;
            return;
          }
          let value: number = parseFloat(
            typeof item[key] === "string"
              ? item[key].replace(/,/g, "")
              : item[key],
          );
          newItem[key] = formatNumberOrEmpty(
            value,
            ProductConfigurationsMicro.RESULTS,
          );
        });
      return newItem;
    },
  );

  const table = useMaterialReactTable({
    columns: columns_,
    data: data_,
    layoutMode: "grid",
    enableTableHead: false,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableExpanding: true,
    enableExpandAll: false,
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        color: `metric` === column.id ? "transparent" : "",
      },
    }),
    state: {
      columnOrder: [
        "mrt-row-expand",
        "mrt-row-actions",
        ...columns_.map((column: any) => column.accessorKey),
      ],
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        table.setExpanded({ [row.id]: !row.getIsExpanded() });
      },
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
        display: !row.subRows?.length ? "none" : "block",
      },
    }),
    muiTableBodyCellProps: ({ column, cell, row }) => ({
      sx: {
        textAlign: column.id === "unit" ? "right !important" : "center",
        paddingLeft:
          column.id === "metric" &&
          !row.subRows?.length &&
          row.original.metric !== "Total"
            ? "25px"
            : "0px",
        borderTop:
          cell.id.charAt(0) === `${data.length - 1}`
            ? "3px solid #cbcbcb!important"
            : "",
        fontWeight: row.id === `${data.length - 1}` ? "bold" : "normal",
        display: !row.subRows?.length
          ? column.id === "mrt-row-expand"
            ? "hidden"
            : "block"
          : null,
        borderRight:
          column.id === "mrt-row-expand" || column.id === "metric"
            ? "none !important"
            : "",
      },
      children:
        column.id === "metric" &&
        cell.getValue() !== "Total" &&
        Array.isArray(cell.row.originalSubRows) ? (
          <Box display={"flex"} justifyContent={"flex-start"}>
            <Box>
              <Typography>{cell.getValue() as React.ReactNode}</Typography>
            </Box>
            <Box
              ml={1}
              display={"flex"}
              alignItems={"center"}
              data-testid={`tooltip-${(cell.getValue() as string)?.toLowerCase()}`}
            >
              <Tooltip
                title={t(
                  `resultsSection.tooltips.${(cell.getValue() as string)?.toLowerCase()}`,
                )}
                arrow
                placement="top"
              >
                <ErrorOutlineIcon
                  sx={{
                    color: `${palette.primary.main}`,
                    transform: "rotate(180deg)",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        ) : column.id === "metric" ? (
          <Box sx={{ textAlign: "left", paddingLeft: "0px !important" }}>
            <Typography variant={"body2"}>
              {cell.getValue() as string}
            </Typography>
          </Box>
        ) : (
          (cell.getValue() as React.ReactNode)
        ),
    }),
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor:
          !row.subRows?.length && row.original.metric !== "Total"
            ? "#f2f2f2"
            : "",
      },
    }),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: ROW_ACTION_COLUMN_WIDTH,
        grow: false,
        enableResizing: false,
      },
      "mrt-row-expand": {
        size: ROW_ACTION_COLUMN_WIDTH,
        grow: false,
        enableResizing: false,
      },
    },
  });

  return (
    <Box className="resultTable" data-testid="micro-results-table-box">
      <MicroTableWrapper>
        <MaterialReactTable table={table} />
      </MicroTableWrapper>
    </Box>
  );
};
