import i18next from "i18next";
import { OptionalMetricsTableResultsType } from "../Types";

function getResultsOverviewTableColumns(
  type: OptionalMetricsTableResultsType,
  baseYear: string,
  targetYear: string,
) {
  return [
    {
      accessorKey: type,
      header: i18next.t(`macro:resultsSection.${type}`),
      id: type,
      size: 150,
      maxSize: 150,
      grow: false,
    },
    {
      accessorKey: "base",
      header: `${baseYear} ${i18next.t("macro:resultsSection.baseYear")}`,
      id: "base",
    },
    {
      accessorKey: "glide",
      header: `${targetYear} ${i18next.t("macro:resultsSection.glidePath")}`,
      id: "glide",
    },
    {
      accessorKey: "scenario",
      header: `${targetYear} ${i18next.t("macro:resultsSection.scenario")}`,
      id: "scenario",
    },
  ];
}

export default getResultsOverviewTableColumns;
