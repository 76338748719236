import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ProductColumnData } from "../../types";
import { useTranslation } from "react-i18next";
import { ViewMicroScenario } from "orval/generated/models";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MicroAdjustmentAccordion from "./MicroAdjustmentAccordion/MicroAdjustmentAccordion";

export type AdjustmentsSectionProps = {
  disabled: boolean;
  productColumnData: ProductColumnData[] | undefined;
  scenario: ViewMicroScenario | undefined;
};
function MicroAdjustmentsSection({
  disabled,
  productColumnData,
  scenario,
}: AdjustmentsSectionProps) {
  const { t } = useTranslation("micro");

  return (
    <Accordion disabled={disabled}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        className="dropdown-left"
      >
        <Typography className="header-H4-bold">
          {t("adjustmentsSection.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {scenario?.products[0]?.adjustments &&
        scenario?.products[0]?.adjustments.length ? (
          scenario?.products[0]?.adjustments?.map((adjustment) => (
            <Box key={adjustment.accordion} data-testid="accordion">
              <MicroAdjustmentAccordion
                products={scenario?.products}
                productColumnData={productColumnData}
                accordion={adjustment}
              />
            </Box>
          ))
        ) : (
          <Typography>
            {t("adjustmentsSection.unableToMakeAdjustments")}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default MicroAdjustmentsSection;
