import { ProductMetricsType } from "./types";

export const ProductMetrics: ReadonlyArray<ProductMetricsType> = [
  {
    key: "year",
    label: "micro:productsSection.table.year",
  },
  {
    key: "category",
    label: "micro:productsSection.table.category",
  },
  {
    key: "brand",
    label: "micro:productsSection.table.brand",
  },
  {
    key: "pack_type",
    label: "micro:productsSection.table.packType",
  },
  {
    key: "pack_size",
    label: "micro:productsSection.table.packSize",
  },
  {
    key: "multipack_size",
    label: "micro:productsSection.table.multiPackSize",
  },
  {
    key: "country_display_name",
    label: "micro:productsSection.table.countryName",
  },
] as const;
