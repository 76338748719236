import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormikProps } from "formik";
import { formikPropsType, MacroAdjustmentStep } from "../../routes/types";

export type MacroAdjustmentsWizardFooterProps = {
  handleLeftButtonClick: () => void;
  steps: (formikProps: FormikProps<formikPropsType>) => MacroAdjustmentStep[];
  formikProps: FormikProps<formikPropsType>;
  activeStep: number;
  handleRightButtonClick: (formikProps: FormikProps<formikPropsType>) => void;
};

const MacroAdjustmentsWizardFooter = ({
  handleLeftButtonClick,
  steps,
  formikProps,
  activeStep,
  handleRightButtonClick,
}: MacroAdjustmentsWizardFooterProps) => {
  const [rightButtonDisabled, setRightButtonDisabled] = useState(true);

  const updateRightButtonDisabled = useCallback((
    formikProps: FormikProps<formikPropsType>,
  ) => {
    switch (activeStep) {
      case 0:
        setRightButtonDisabled(
          formikProps.values.name === "" ||
          formikProps.values.inputArea === "" ||
          formikProps.values.name === null ||
          formikProps.values.inputArea === null,
        );
        break;
      default:
        setRightButtonDisabled(false);
    }
  }, [activeStep]);

  useEffect(() => {
    updateRightButtonDisabled(formikProps);
  }, [activeStep, formikProps, updateRightButtonDisabled]);

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleLeftButtonClick()}
      >
        {steps(formikProps)[activeStep].leftBtnText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleRightButtonClick(formikProps)}
        disabled={rightButtonDisabled}
      >
        {steps(formikProps)[activeStep].rightBtnText}
      </Button>
    </Box>
  );
};

export default MacroAdjustmentsWizardFooter;
