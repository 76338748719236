import { createContext, useContext } from "react";

interface TermsContextType {
  isTermsAccepted: boolean | undefined;
  setIsTermsAccepted: (value: boolean) => void;
}

export const TermsContext = createContext<TermsContextType | undefined>(
  undefined
);

export const useTermsContext = () => {
  const context = useContext(TermsContext);
  if (!context) {
    throw new Error("useTermsContext must be used within a TermsProvider");
  }
  return context;
};
