import { TextField, Box, Chip } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { KeyboardEvent } from "react";

interface TextEditProps {
  value: string;
  onSaveInput: Function;
  chipLabel?: string;
  className?: string;
  testId?: string;
}

export const TextEdit = ({
  value,
  onSaveInput,
  chipLabel,
  className,
  testId = "text-input",
}: TextEditProps) => {
  const validationSchema = yup.object({
    textValue: yup
      .string()
      .max(60, "Name must be at most 60 characters")
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
  });

  const formik = useFormik<{ textValue: string }>({
    initialValues: {
      textValue: value,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ textValue }) => onSaveInput(textValue),
  });

  const valueWidth =
    (formik.values.textValue?.length ?? 0) < 9
      ? 150
      : (formik.values.textValue?.length ?? 0) * 11 + 50;

  return (
    <Box
      className="text-edit"
      display={"flex"}
      alignItems={"center"}
      width={valueWidth}
      maxWidth={valueWidth}
    >
      <TextField
        value={formik.values.textValue}
        name="textValue"
        data-testid={testId}
        variant="outlined"
        onChange={formik.handleChange}
        onBlur={() => formik.handleSubmit()}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            formik.handleSubmit();
          }
        }}
        error={formik.touched.textValue && Boolean(formik.errors.textValue)}
        helperText={
          formik.touched.textValue && (formik.errors.textValue as string)
        }
        focused
        autoFocus
        className={className}
        fullWidth
      />
      {chipLabel && <Chip sx={{ ml: 2 }} label={chipLabel} />}
    </Box>
  );
};
