import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

export interface NoDataProps {
  label?: string;
  label2?: string;
  actionButtonLabel?: string;
  onActionButtonClick?: Function;
}

export const NoData = ({
  label,
  label2,
  actionButtonLabel,
  onActionButtonClick,
}: NoDataProps) => {
  const { t } = useTranslation();
  const noDataMessage1 = label ?? t("common:labels.noDataPresent1");
  const noDataMessage2 = label2 ?? t("common:labels.noDataPresent2");

  return (
    <Box
      sx={{
        height: "171px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "thin dotted",
      }}
      data-testid="no-data-box"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box pb={3}>
          <Typography className="header-H2">{noDataMessage1}</Typography>
        </Box>
        <Box>
          <Typography className="header-H3">{noDataMessage2}</Typography>
        </Box>
      </Box>
      {actionButtonLabel && (
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          onClick={() => {
            if (onActionButtonClick) {
              onActionButtonClick();
            }
          }}
        >
          {t(actionButtonLabel)}
        </Button>
      )}
    </Box>
  );
};
