export type handleSearchFilterProps = {
  year: number[];
  brand: string[];
  brandGroup: string[];
  category: string[];
  packSize: number[];
  packType: string[];
  countryDisplayName: string[];
  multipack: number[];
};

export const emptyHandleSearchFilterProps = {
  year: [],
  brand: [],
  brandGroup: [],
  category: [],
  packSize: [],
  packType: [],
  countryDisplayName: [],
  multipack: [],
};
