import { RouteObject } from "react-router-dom";
import { ToolConfigurationRoutesConfig } from "./config";
import { ToolConfigurationPage } from "../routes/ToolConfigurationPage";
import BannerAlertConfigurationPage from "../components/BannerAlertConfigurationPage/BannerAlertConfigurationPage";
import UserManagementPage from "../components/UserManagementPage/UserManagementPage";

export const ToolConfigurationRoutes: RouteObject = {
  path: ToolConfigurationRoutesConfig.toolConfigurationPage,
  children: [
    { index: true, element: <ToolConfigurationPage /> },
    {
      path: ToolConfigurationRoutesConfig.alertConfigurationPage,
      element: <BannerAlertConfigurationPage />,
    },
    {
      path: ToolConfigurationRoutesConfig.userManagementPage,
      element: <UserManagementPage />,
    },
  ],
};
