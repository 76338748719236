// NB: this was moved outside the types.ts file because
// it was not being exported  and imported correctly in the tests

export enum pillarDeepDiveAccessorKeys {
  INGREDIENTS = "ingredients",
  PACKAGING = "packaging",
  MANUFACTURING = "manufacturing",
  LOGISTICS = "logistics",
  CDE = "cde",
}

export default pillarDeepDiveAccessorKeys;
