import { useSortable } from "@dnd-kit/sortable";
import { SelectedProductRow } from "../SelectedProductRow/SelectedProductRow";
import { Box, IconButton } from "@mui/material";
import DragIndicator from "@mui/icons-material/DragIndicator";
import { Product } from "orval/generated/models";
interface SortableItemProps {
  id: string;
  item: Product;
  selectedProducts: Product[];
  handleRemoveSelectedProduct: (guid: string) => void;
  overlay?: boolean;
}

export function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
  });

  return (
    <Box
      ref={setNodeRef}
      display={"flex"}
      flexDirection={"row"}
      sx={{
        display: "flex",
        border: `solid 0.5px #6A6A6A`,
        borderRadius: 1,
        marginBottom: 1,
        marginRight: 1.25,
        padding: 0.5,
        backgroundColor: "white",
        opacity: isDragging && props.overlay === false ? 0.3 : undefined,
        alignItems: "center",
      }}
    >
      <Box width={"10%"}>
        <IconButton
          aria-label="drag"
          sx={{ marginRight: 1 }}
          {...attributes}
          {...listeners}
        >
          <DragIndicator />
        </IconButton>
      </Box>
      <SelectedProductRow
        item={props.item}
        handleRemoveSelectedProduct={props.handleRemoveSelectedProduct}
      />
    </Box>
  );
}
