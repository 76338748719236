export type DataExtractionFormArrayType = {
  number: number;
  title: string;
  description: string;
  additionalReactComponent: JSX.Element;
};

export enum DataTypeSelectValue {
  None = "",
  ConfigurationData = "Configuration Data",
  NumericData = "Numeric Data",
}

export interface ListStructure {
  display_name: string;
  columns: string[];
}

export interface ApiResponse {
  [key: string]: ListStructure;
}
