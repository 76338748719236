import { Box } from "@mui/material";
import { SideBarOptions } from "./SideBarConstants";
import { SideBarOption } from "./types";
import { usePermissions } from "core/navigation/usePermissions";
import SidebarOption from "./SidebarOption/SidebarOption";

export default function Sidebar() {
  const { hasFunctionalAccess } = usePermissions();
  return (
    <>
      {hasFunctionalAccess && (
        <Box className="sidebar" zIndex={100}>
          <ul>
            {SideBarOptions.map((option: SideBarOption, index: number) => (
              <SidebarOption key={index} option={option} />
            ))}
          </ul>
        </Box>
      )}
    </>
  );
}
