import { RouteObject } from "react-router-dom";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { MacroToolRoutesConfig } from "./config";
import MacroToolPage from "../routes/MacroToolPage/MacroToolPage";
import CreateScenarioPage from "../routes/MacroToolScenarioPage/MacroToolScenarioPage";
import { RootPage } from "components/sections";
import { AlertStatusEnum, AlertViewModel } from "orval/generated/models";
import BannerAlert from "components/common/BannerAlert/BannerAlert";
import { AppConstant } from "constants/AppConstant";
import MacroAdjustmentsPage from "../routes/MacroAdjustmentsPage/MacroAdjustmentsPage";
import MacroAdjustmentWizard from "../routes/MacroAdjustmentWizard/MacroAdjustmentWizard";

const alert: AlertViewModel = {
  id: 1000,
  message:
    "Please note that the Macro Tool is a summation of all the product level carbon footprints and will not equal corporate reporting numbers. This is due to different methodology (e.g. this Tool excludes business travel and central office emissions) and different assumptions (e.g. currently a lower aluminium EF is used in the Tool vs. reporting)",
  type: AlertStatusEnum.information,
};
const activeNotification = new Date() <= new Date("2025-03-31");

export const MacroToolRoutes: RouteObject = {
  path: MacroToolRoutesConfig.countryLevelAnalytics,
  element: (
    <ProtectedRoutes>
      {activeNotification ? (
        <BannerAlert
          activeAlert={alert}
          localStorageKey={
            AppConstant.localStorageKeys.dismissedMacroNotification
          }
        />
      ) : null}
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    { index: true, element: <MacroToolPage /> },
    {
      path: MacroToolRoutesConfig.scenarioPage,
      element: <CreateScenarioPage />,
    },
    {
      path: MacroToolRoutesConfig.adjustmentsPage,
      element: (
        <ProtectedRoutes restrictToPwCEmail>
          <MacroAdjustmentsPage />
        </ProtectedRoutes>
      ),
    },
    {
      path: MacroToolRoutesConfig.adjustmentsWizard,
      element: (
        <ProtectedRoutes restrictToPwCEmail>
          <MacroAdjustmentWizard />
        </ProtectedRoutes>
      ),
    },
  ],
};
