export const getEnvironmentVariableValue = (
  key: string,
): string | boolean | undefined => {
  const environmentValue = process.env[key];

  if (environmentValue === "true") {
    return true;
  }
  if (environmentValue === "false") {
    return false;
  }
  return environmentValue || undefined;
};
