import { SideBarOption } from "./types";
import { HomeRoutesConfig } from "../../../features/home/navigation/config";
import { MicroToolRoutesConfig } from "../../../features/micro-tool/navigation/config";
import { MacroToolRoutesConfig } from "../../../features/macro-tool/navigation/config";
import { ToolConfigurationRoutesConfig } from "../../../features/tool-configuration/navigation/config";
import { Icons } from "../../common";
import { DataManagementRoutesConfig } from "../../../features/data-management/navigation/config";
import { Permission } from "../../../core/navigation/permission";

export const SideBarOptions: SideBarOption[] = [
  {
    title: "sidePanel.options.home",
    icon: Icons.HOME,
    redirectURL: HomeRoutesConfig.homePage,
  },
  {
    title: "sidePanel.options.microTool",
    icon: Icons.TOGGLE,
    redirectURL: MicroToolRoutesConfig.productLevelAnalytics,
    permission: Permission.ProductLevelAnalytics,
  },
  {
    title: "sidePanel.options.macroTool",
    icon: Icons.GROW_RESPONSE,
    redirectURL: MacroToolRoutesConfig.countryLevelAnalytics,
    permission: Permission.CountryLevelAnalytics,
  },
  {
    title: "sidePanel.options.dataManagement",
    icon: Icons.DATABASE,
    redirectURL: DataManagementRoutesConfig.dataManagementPage,
    permission: Permission.DataManagement,
  },
  {
    title: "sidePanel.options.toolConfiguration",
    icon: Icons.SETTINGS,
    redirectURL: ToolConfigurationRoutesConfig.toolConfigurationPage,
    permission: Permission.ToolConfiguration,
  },
];
