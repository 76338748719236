import { Box, IconButton, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import { useState } from "react";
import { SelectDropdownListItem } from "components/common/SelectDropdown/SelectDropdownRow/SelectDropdownRow";

export interface HeaderProps {
  name: string;
  filtersList?: SelectDropdownListItem<string>[] | string[];
  selectedFilters?: string[];
  handleFilterChange?: (selectedItems: string[]) => void;
}

const Header = ({
  name,
  filtersList,
  selectedFilters,
  handleFilterChange,
}: HeaderProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const handleShowFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <>
      {filtersList ? (
        <Box
          pt={2}
          pb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "inherit" }}
          data-testid="ben-test"
        >
          <Box
            mr={3}
            height="2.5em"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ minWidth: "200px", maxWidth: "210px" }}
          >
            {!showFilters ? (
              <Box>
                <Typography
                  sx={{
                    fontSize: ".875rem",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: 500,
                  }}
                  mr={2}
                  data-testid="header-title"
                >
                  {name}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ width: "95%" }}>
                {filtersList && handleFilterChange && (
                  <SelectDropdown
                    listItems={filtersList}
                    onSave={handleFilterChange}
                    savedSelectedItems={selectedFilters || []}
                    title={name}
                    requireSave={false}
                    showSearchBar
                    selectAll
                    compact
                  />
                )}
              </Box>
            )}
          </Box>
          <Box>
            <IconButton
              onClick={handleShowFilters}
              sx={{ maxHeight: "20px" }}
              data-testid="filter-icon-btn"
            >
              <FilterAltOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          pt={2}
          pb={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: "100%" }}
        >
          {name}
        </Box>
      )}
    </>
  );
};
export default Header;
