import { useMemberInfo } from "../MemberInfoProvider/MemberInfoProvider";
import { Permission } from "./permission";

export const usePermissions = () => {
  const { memberInfo, hasPermission } = useMemberInfo();

  const useHasPermission = (permission?: Permission): boolean => {
    if (permission === Permission.ToolConfiguration) {
      return [Permission.Notifications, Permission.UserManagement].some(
        hasPermission,
      );
    }
    return permission ? hasPermission(permission) : false;
  };

  const hasUserManagementPermission = useHasPermission(
    Permission.UserManagement,
  );
  const hasAlertsPermission = useHasPermission(Permission.Notifications);
  const hasFunctionalAccess =
    (memberInfo?.permissions.functional_access ?? []).length > 0;

  return {
    useHasPermission,
    hasUserManagementPermission,
    hasAlertsPermission,
    hasFunctionalAccess,
  };
};
