import {
  ProductConfigurationsMicro,
  rowValidationType,
} from "../MicroAdjustmentsTable/types";
import { ManufacturingTableMetrics } from "./TableConfig/ManufacturingTable";
import { BodyAndLidPackagingMetrics } from "./TableConfig/PackagingTable";
import { BodyAndLidEmissionFactorsTableMetrics } from "./TableConfig/BodyAndLidEmissionFactorsTable";
import { SKUAdjustmentAccordionDetails } from "orval/generated/models";
import { LogisticsTableMetrics } from "./TableConfig/LogisticsTable";
import { ServingSizeMetrics } from "features/micro-tool/components/MicroAdjustmentsSection/TableConfig/ServingSizeTable";

const generateConfig = (
  accordion: SKUAdjustmentAccordionDetails,
  t: Function,
): rowValidationType[] => {
  switch (accordion.accordion) {
    case ProductConfigurationsMicro.SERVING_SIZE:
      return [
        {
          metric: "Serving size (ml)",
          field_key: ServingSizeMetrics[0].field_key as string,
          type: "unit",
          isNonZero: true,
        } as rowValidationType,
      ];

    case ProductConfigurationsMicro.MANUFACTURING:
      return ManufacturingTableMetrics.map((item) => {
        return {
          metric: t(item.label),
          field_key: item.field_key,
          type: "unit",
          isNonZero: false,
        };
      });

    case ProductConfigurationsMicro.LOGISTICS:
      return LogisticsTableMetrics.map((item) => {
        return {
          metric: t(item.label),
          field_key: item.field_key,
          type: "unit",
          isNonZero: false,
        };
      });

    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
      return BodyAndLidPackagingMetrics.map((item) => {
        const label = t(item.label);
        return {
          metric: label,
          field_key: item.field_key,
          type: label.includes("(%)") ? "percentage" : "unit",
          isNonZero: label.includes("Net"),
        };
      });

    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
      return BodyAndLidEmissionFactorsTableMetrics.map((item) => {
        return {
          metric: t(item.label),
          field_key: item.field_key,
          type: "unit",
          isNonZero: false,
        };
      });

    default:
      let metricDescription = "";
      if (accordion.accordion === ProductConfigurationsMicro.INGREDIENTS) {
        metricDescription = " per litre (g / litre)";
      } else if (
        accordion.accordion ===
        ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS
      ) {
        metricDescription = " - EF (g CO₂e / g ingredient)";
      }

      return (
        accordion.fields?.map((field) => ({
          metric: `${t(`common:${field.field}`)}${metricDescription}`,
          field_key: field.field,
          type:
            !field.field.includes("weight") && !field.field.includes("ef")
              ? "percentage"
              : "unit",
          isNonZero: false,
        })) || []
      );
  }
};

export default generateConfig;
