export enum SortValues {
  DATE_UPDATED_DESCENDING = 0,
  DATE_UPDATED_ASCENDING = 1,
  NAME_ASCENDING = 2,
  NAME_DESCENDING = 3,
}

export const SortConfig: { key: string; value: SortValues }[] = [
  {
    key: "common:sorting.mostRecentFirst",
    value: SortValues.DATE_UPDATED_DESCENDING,
  },
  {
    key: "common:sorting.leastRecentFirst",
    value: SortValues.DATE_UPDATED_ASCENDING,
  },
  {
    key: "common:sorting.nameAscending",
    value: SortValues.NAME_ASCENDING,
  },
  {
    key: "common:sorting.nameDescending",
    value: SortValues.NAME_DESCENDING,
  },
];
