export function formatText(str: string) {
  return str?.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
}

export function revertTextFormat(str: string) {
  return str
    ?.replace(/([a-z])([A-Z])|\s+/g, (_, lower, upper) => {
      if (lower && upper) {
        return `${lower}_${upper.toLowerCase()}`;
      } else {
        return "_";
      }
    })
    .toLowerCase();
}
