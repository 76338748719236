import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken } from "../../utils/HTTPUtils/http-utils";
import { HTTPConstants } from "../../utils/HTTPUtils/constants";

// Create an Axios instance with base configuration
export const AXIOS_INSTANCE = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Setup request interceptor to automatically add the token to headers
AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (config.url?.includes(HTTPConstants.refreshTokenURL)) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      delete config.headers.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export async function withCustomAxios<T>(
  params?: AxiosRequestConfig,
  extraParams?: AxiosRequestConfig,
): Promise<T> {
  const response = await AXIOS_INSTANCE({ ...params, ...extraParams });
  return response.data;
}
