import { getCookies } from "typescript-cookie";
import { jwtDecode } from "jwt-decode";
import { CookieConfig, TokenTypes } from "./enums";
import { AuthenticationProviders } from "constants/AuthenticationProviders";
import { AppConstant } from "constants/AppConstant";

const decodeJWTToken = (token: string) => {
  return jwtDecode(token);
};

const getSessionTokenForKey = (sessionKey: string) => {
  if (AppConstant.authentication?.provider === AuthenticationProviders.IDAM) {
    return sessionStorage.getItem(TokenTypes.ACCESS_TOKEN);
  } else {
    const cookies = getCookies();
    for (const key in cookies) {
      if (
        key.startsWith(CookieConfig.baseReference) &&
        key.endsWith(sessionKey)
      ) {
        return cookies[key] || "";
      }
    }
  }
};

const getDecodedSessionInfo = (
  sessionKey:
    | TokenTypes.ACCESS_TOKEN
    | TokenTypes.ID_TOKEN
    | TokenTypes.REFRESH_TOKEN,
) => {
  const tokenValue: string | null | undefined =
    getSessionTokenForKey(sessionKey);
  if (tokenValue) {
    return decodeJWTToken(tokenValue) || {};
  } else return undefined;
};

export { decodeJWTToken, getSessionTokenForKey, getDecodedSessionInfo };
