import React, { useState, useCallback } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import {
  Box,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { AppConstant } from "constants/AppConstant";

export type UploadFilesProps = {
  setFiles: (value: File[]) => void;
  files: File[];
  validMIMEFileTypes?: string[];
  multiple?: boolean;
  maxFilesNumber?: number;
  maxFileSize?: number;
};

const UploadFiles = ({
  files,
  setFiles,
  validMIMEFileTypes,
  multiple = true,
  maxFilesNumber,
  maxFileSize, // in MB
}: UploadFilesProps) => {
  const toMbDivider = 1024 * 1024;
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleNewFiles = useCallback(
    (_files: FileList) => {
      let error = "";

      const newFiles = Array.from(_files).filter(
        (file) => !validMIMEFileTypes || validMIMEFileTypes.includes(file.type),
      );

      if (maxFilesNumber && _files.length + files.length > maxFilesNumber) {
        error = `Number of files should not exceed a maximum of ${maxFilesNumber} files`;
      } else if (validMIMEFileTypes && newFiles.length < _files.length) {
        error = `Allowed file types are ${validMIMEFileTypes.map((type) => AppConstant.fileMIMETypesExtentions[type as keyof typeof AppConstant.fileMIMETypesExtentions]).join(", ")}`;
      } else if (
        maxFileSize &&
        newFiles.find((file) => file.size / toMbDivider > maxFileSize)
      ) {
        error = `Maximum allowed file size is ${maxFileSize} MB`;
      }

      setErrorMessage(error || "");

      if (newFiles.length && !error) {
        setFiles([...files, ...newFiles]);
      }
    },
    [
      maxFilesNumber,
      files,
      validMIMEFileTypes,
      maxFileSize,
      toMbDivider,
      setFiles,
    ],
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        handleNewFiles(selectedFiles);
      }
    },
    [handleNewFiles],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const droppedFiles = event.dataTransfer.files;
      handleNewFiles(droppedFiles);
    },
    [handleNewFiles],
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      const newFiles = files.filter((_file, i) => i !== index);
      setFiles(newFiles);
    },
    [files, setFiles],
  );

  return (
    <Box width="100%">
      <Box
        width="100%"
        py={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "4px",
          border: `1px dashed ${theme.palette.primary.main}`,
        }}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <UpgradeIcon
          sx={{
            fontSize: 50,
            fontWeight: 700,
            mb: 3,
            color: theme.palette.primary.main,
          }}
          data-testid="upload-icon"
        />
        <input
          type="file"
          hidden
          id="browse"
          onChange={handleFileChange}
          {...(validMIMEFileTypes
            ? { accept: validMIMEFileTypes.join(",") }
            : {})}
          multiple={multiple}
          disabled={!!(maxFilesNumber && files.length === maxFilesNumber)}
        />
        <Typography>
          Drag and Drop or{" "}
          <label
            htmlFor="browse"
            style={{
              cursor:
                maxFilesNumber && files.length === maxFilesNumber
                  ? "not-allowed"
                  : "pointer",
              color:
                files.length === 3
                  ? theme.palette.grey[400]
                  : theme.palette.primary.main,
            }}
          >
            Choose File
          </label>{" "}
          to Upload
        </Typography>

        {files.length > 0 && (
          <Box
            my={4}
            sx={{ border: "1px dashed grey", borderRadius: 2, width: "80%" }}
          >
            <List dense sx={{ width: "100%" }}>
              {files.map((file, index) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(index)}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  }
                  key={index}
                >
                  <ListItemText
                    primary={file.name}
                    secondary={(file.size / toMbDivider).toFixed(2) + " MB"}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {files.length > 0 && (
          <Typography
            variant="caption"
            sx={{ color: "green", fontWeight: 700 }}
          >
            {files.length} file(s) selected
          </Typography>
        )}
      </Box>
      {errorMessage && (
        <FormHelperText sx={{ mt: 1, ml: 1 }} error>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default UploadFiles;
