import Box from "@mui/material/Box";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback } from "react";
import "./MekkoFilters.scss";
import FiltersContainer from "../../../../../components/common/FiltersContainer/FiltersContainer";

export type MekkoFiltersProps = {
  years: string[];
  categories: string[];
  handleSetMekkoYear: (year: string) => void;
  setSelectedMekkoCategories: (categories: string[]) => void;
};

export function MekkoFilters({
  years,
  categories,
  handleSetMekkoYear,
  setSelectedMekkoCategories,
}: MekkoFiltersProps) {
  const { t } = useTranslation("macro");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");

  const handleSelectedYear = (e: any) => {
    const value = e.target.value as string;
    setSelectedYear(value);
    if (handleSetMekkoYear) {
      handleSetMekkoYear(value);
    }
  };

  const handleCategoriesTypeChange = useCallback(
    (newSelectedCategories: string[]) => {
      setSelectedMekkoCategories(newSelectedCategories);
    },
    [setSelectedMekkoCategories],
  );

  useEffect(() => {
    if (categories.length > 0 && selectedCategory === "") {
      setSelectedCategory(categories[0]);
      handleCategoriesTypeChange(categories);
    }
    if (years.length > 0 && selectedYear === "") {
      setSelectedYear(years[0]);
    }
  }, [
    categories,
    handleCategoriesTypeChange,
    selectedCategory,
    selectedYear,
    years,
  ]);

  return (
    <FiltersContainer
      leftFilters={
        <Box display="flex" gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth>
                <InputLabel htmlFor="year" id="year">
                  {t("mekkoAnalysis.labels.year")}
                </InputLabel>
                <Select
                  id="year"
                  labelId="year"
                  label={t("mekkoAnalysis.labels.year")}
                  onChange={handleSelectedYear}
                  value={selectedYear}
                  data-testid="mekko-year-select"
                  variant="outlined"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      }
    ></FiltersContainer>
  );
}
