import { Box, Typography } from "@mui/material";

export type MicroColumnWithUnitsProps = {
  renderedCellValue: any;
};
export function MicroColumnWithUnits({ renderedCellValue }: MicroColumnWithUnitsProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Typography
        sx={{
          textAlign: "left",
          fontWeight: renderedCellValue === "Total" ? "bold" : "normal",
        }}
      >
        {renderedCellValue}
      </Typography>
    </Box>
  );
}
