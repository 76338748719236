import type { PropsWithChildren } from "react";
import { DragOverlay } from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";

interface Props {}

export function SortableOverlay({ children }: PropsWithChildren<Props>) {
  return (
    <DragOverlay
      dropAnimation={{
        duration: 50,
        easing: "cubic-bezier(0.4, 0, 1, 1)",
      }}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      {children}
    </DragOverlay>
  );
}
