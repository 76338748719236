import { CustomIconProps, Icons } from "./types";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../../../assets/icons/chevronDoubleRight.svg";
import { ReactComponent as DatabaseIcon } from "../../../assets/icons/database.svg";
import { ReactComponent as DownloadSimpleIcon } from "../../../assets/icons/downloadSimple.svg";
import { ReactComponent as GrowRevenueIcon } from "../../../assets/icons/growRevenue.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as ToggleIcon } from "../../../assets/icons/toggle.svg";
import { ReactComponent as CircleDeleteIcon } from "../../../assets/icons/circleDelete.svg";
import { ReactComponent as ExclamationIcon } from "../../../assets/icons/exclamation.svg";
import { ReactComponent as ConfirmedIcon } from "../../../assets/icons/confirmed.svg";
import { ReactComponent as InformationIcon } from "../../../assets/icons/information.svg";
import { CustomIconDefaultConfig } from "./config";

export function getIcon({ name, width, height, fill }: CustomIconProps): any {
  const iconWidth = width || CustomIconDefaultConfig.width;
  const iconHeight = height || CustomIconDefaultConfig.height;
  switch (name) {
    case Icons.ADD:
      return <AddIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.ARROW:
      return <ArrowIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.CHEVRON_DOUBLE_RIGHT:
      return (
        <ChevronDoubleRightIcon
          width={iconWidth}
          height={iconHeight}
          fill={fill}
        />
      );
    case Icons.DATABASE:
      return <DatabaseIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.DOWNLOAD_SIMPLE:
      return (
        <DownloadSimpleIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.GROW_RESPONSE:
      return (
        <GrowRevenueIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.HOME:
      return <HomeIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.SETTINGS:
      return <SettingsIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.TOGGLE:
      return <ToggleIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.CIRCLE_DELETE:
      return <CircleDeleteIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.EXCLAMATION:
      return <ExclamationIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.CONFIRMED:
      return <ConfirmedIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.INFORMATION:
      return <InformationIcon width={iconWidth} height={iconHeight} fill={fill} />;
    default:
      break;
  }
}
