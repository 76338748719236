import { initReactI18next } from "react-i18next";
import * as config from "./config";
import { namespaces } from "./namespaces";
import i18n from "i18next";

const resources = namespaces.reduce(
  (acc: Record<string, Record<string, string>>, n: string) => {
    config.supportedLanguages.forEach((lng) => {
      if (!acc[lng]) acc[lng] = {};
      acc[lng] = {
        ...acc[lng],
        [n]: require(`../../assets/locales/${lng}/${n}.json`),
      };
    });
    return acc;
  },
  {},
);

await i18n.use(initReactI18next).init({
  lng: config.defaultLanguage,
  fallbackLng: config.fallbackLanguage,
  ns: namespaces,
  defaultNS: config.defaultNameSpace,
  debug: true,
  resources,
});
