import {
  TableResultsFilterDataType,
  TableResultsType,
} from "../../ScenarioResults/types";

function getTitle(
  sectionType: TableResultsType,
  units: TableResultsFilterDataType,
) {
  switch (sectionType) {
    case TableResultsType.BY_CATEGORY:
      return units === TableResultsFilterDataType.ABSOLUTE
        ? "dataVisualisationTabSection.timeEvolution.titles.categoryAbsoluteTitle"
        : "dataVisualisationTabSection.timeEvolution.titles.categoryPerLitreTitle";
    case TableResultsType.BY_PACK_TYPE:
      return units === TableResultsFilterDataType.ABSOLUTE
        ? "dataVisualisationTabSection.timeEvolution.titles.byPackAbsoluteTitle"
        : "dataVisualisationTabSection.timeEvolution.titles.byPackPerLitreTitle";
    default:
      return units === TableResultsFilterDataType.ABSOLUTE
        ? "dataVisualisationTabSection.timeEvolution.titles.categoryAbsoluteTitle"
        : "dataVisualisationTabSection.timeEvolution.titles.categoryPerLitreTitle";
  }
}

export default getTitle;
