import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

export type AccordionComponentProps = {
  disabled?: boolean;
  children: ReactNode;
  title: string;
  testId?: string;
};

export function AccordionComponent({
  disabled,
  children,
  title,
  testId,
}: AccordionComponentProps) {
  return (
    <Accordion disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} data-testid={testId}>
        <Typography className="header-H4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box pt={1}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
}
