import { HistoryOutlined } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export const dataManagementTabs = {
  tabs: [
    {
      label: "productConfiguration:dataManagementPage.tabLabels.versionHistory",
      icon: HistoryOutlined,
    },
    {
      label: "productConfiguration:dataManagementPage.tabLabels.dataExtraction",
      icon: FileDownloadOutlinedIcon,
    },
  ],
} as const;
