import Box from "@mui/material/Box";
import { AccordionComponent } from "../../AccordionComponent";
import { useTranslation } from "react-i18next";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import EmissionTimeSection from "../../EmissionTimeSection/EmissionTimeSection";
import { TableResultsType } from "../types";
import WaterfallChartSection from "../../WaterfallChart/WaterfallChartSection";
import { MekkoAccordion } from "../../MekkoChartSection/MekkoAccordion/MekkoAccordion";

export type DataVisualisationTabSectionProps = {
  disabled: boolean;
  scenario: ViewMacroScenarioViewModel | null;
};

export function DataVisualisationTabSection({
  disabled,
  scenario,
}: DataVisualisationTabSectionProps) {
  const { t } = useTranslation("macro");

  const subAccordionLabelsTimeEvolution = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <EmissionTimeSection
          scenario={scenario}
          sectionType={TableResultsType.BY_CATEGORY}
        />
      ),
      testId: "time-evolution-by-category",
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <EmissionTimeSection
          scenario={scenario}
          sectionType={TableResultsType.BY_PACK_TYPE}
        />
      ),
      testId: "time-evolution-by-pack",
    },
  ];

  const subAccordionLabelsWaterfall = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <WaterfallChartSection
          scenario={scenario}
          sectionType={TableResultsType.BY_CATEGORY}
        />
      ),
      testId: "waterfall-by-category",
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <WaterfallChartSection
          scenario={scenario}
          sectionType={TableResultsType.BY_PACK_TYPE}
        />
      ),
      testId: "waterfall-by-pack",
    },
  ];

  return (
    <Box mb={10}>
      <AccordionComponent
        title={t(
          "dataVisualisationTabSection.accordionLabels.emissionsHeatMap",
        )}
        disabled={disabled}
        testId={"accordion-emissions-heat-map"}
      >
        <MekkoAccordion scenario={scenario} />
      </AccordionComponent>

      <AccordionComponent
        title={t("dataVisualisationTabSection.accordionLabels.timeEvolution")}
        disabled={disabled}
        testId={"accordion-time-evolution"}
      >
        {subAccordionLabelsTimeEvolution.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent
              title={t(item.label)}
              disabled={disabled}
              testId={`accordion-${item.testId}`}
            >
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
      <AccordionComponent
        title={t("dataVisualisationTabSection.accordionLabels.waterfall")}
        disabled={disabled}
        testId={"accordion-waterfall"}
      >
        {subAccordionLabelsWaterfall.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent
              title={t(item.label)}
              disabled={disabled}
              testId={`accordion-${item.testId}`}
            >
              <Box display="flex" gap="32px" justifyContent="space-between">
                {item.component}
              </Box>
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
    </Box>
  );
}
