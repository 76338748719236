import axios from "axios";
import { getBaseURL } from "utils/HTTPUtils/http-utils";
import { EnvironmentVariable } from "constants/EnvironmentVariables";
import { AuthSessionStorageKeys } from "../navigation/config";
import { decodeJWTToken } from "utils/HTTPUtils/session-service";
import { JwtPayload } from "jwt-decode";
import { AppConstant } from "constants/AppConstant";

const { idamConfig } = EnvironmentVariable;

interface AuthObject {
  access_token: string;
  refresh_token: string;
  id_token: string;
  scope: "openid";
  token_type: "Bearer";
  expires_in: number;
}

export async function getIDAMAccessToken(code: string) {
  try {
    const baseURL = `${getBaseURL(idamConfig.idamAPIURL)}access_token`;
    const clientId = getBaseURL(idamConfig.clientId);
    const clientSecret = getBaseURL(idamConfig.clientSecret);
    const redirectURL = `${getBaseURL(idamConfig.appURL)}/auth`;
    const params = new URLSearchParams();
    params.append("client_id", clientId ?? "");
    params.append("client_secret", clientSecret ?? "");
    params.append("redirect_uri", redirectURL ?? "");
    params.append("code", code);
    params.append("grant_type", "authorization_code");
    const response = await axios.post(baseURL, params);
    return response.data;
  } catch (err) {
    console.error("Unable to get a token.");
    return;
  }
}

export async function revokeTokenAndEndSession(
  initiateLogout: (idToken: string) => void,
) {
  try {
    const baseURL = `${getBaseURL(idamConfig.idamAPIURL)}token/revoke`;
    const idToken =
      sessionStorage.getItem(AuthSessionStorageKeys.idToken) || "";
    const accessToken = sessionStorage.getItem(
      AuthSessionStorageKeys.accessToken,
    );
    const clientId = getBaseURL(idamConfig.clientId);
    const clientSecret = getBaseURL(idamConfig.clientSecret);
    const params = new URLSearchParams();
    params.append("client_id", clientId ?? "");
    params.append("client_secret", clientSecret ?? "");
    params.append("token", accessToken ?? "");
    const response = await axios.post(baseURL, params);
    if (response?.status === 200) {
      clearAuthDataFromSessionStorage();
      initiateLogout(idToken);
      return true;
    }
  } catch (err) {
    console.error("Unable to revoke token and logout.");
    return err;
  }
}

export function isTokenExpired(idToken: string): boolean {
  const decodedToken: JwtPayload = decodeJWTToken(idToken);
  const currentTime = Math.floor(Date.now() / 1000);

  if (decodedToken && decodedToken.exp) {
    return decodedToken.exp <= currentTime;
  }

  return true;
}

export const clearAuthDataFromSessionStorage = (): void => {
  sessionStorage.removeItem(AuthSessionStorageKeys.accessToken);
  sessionStorage.removeItem(AuthSessionStorageKeys.userInfo);
  sessionStorage.removeItem(AuthSessionStorageKeys.idToken);
  sessionStorage.removeItem(AuthSessionStorageKeys.refreshToken);
  localStorage.removeItem(AppConstant.localStorageKeys.dismissedBannerMessage);
  localStorage.removeItem(
    AppConstant.localStorageKeys.dismissedMacroNotification,
  );
};

export const handleAddAuthObjectToSessionStorage = (
  authObject: AuthObject,
): void => {
  if (authObject) {
    clearAuthDataFromSessionStorage();
    sessionStorage.setItem(
      AuthSessionStorageKeys.accessToken,
      authObject.access_token,
    );
    sessionStorage.setItem(AuthSessionStorageKeys.idToken, authObject.id_token);
    sessionStorage.setItem(
      AuthSessionStorageKeys.refreshToken,
      authObject.refresh_token,
    );
    const userInfo = decodeJWTToken(authObject.id_token);
    if (userInfo) {
      sessionStorage.setItem(
        AuthSessionStorageKeys.userInfo,
        JSON.stringify(userInfo),
      );
    }
  }
};
