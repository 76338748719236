import { useNavigate } from "react-router-dom";
import { getBaseURL } from "utils/HTTPUtils/http-utils";
import { isTokenExpired } from "./verifyPwCAccess";
import { EnvironmentVariable } from "constants/EnvironmentVariables";

const useInitiateLogout = () => {
  const navigate = useNavigate();
  const { idamConfig } = EnvironmentVariable;

  return (idToken: string): void => {
    const redirect_uri = `${getBaseURL(idamConfig.appURL)}/auth/logout`;

    if (isTokenExpired(idToken)) {
      navigate(redirect_uri);
      return;
    }

    const endSessionAndRedirectURL = `${getBaseURL(idamConfig.idamLogoutURL)}id_token_hint=${idToken}&post_logout_redirect_uri=${redirect_uri}`;
    navigate(endSessionAndRedirectURL);
  };
};

export default useInitiateLogout;
