import { Typography, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { FC, useEffect, useState } from "react";
import { AppConstant } from "constants/AppConstant";
import { UserDataTypes } from "orval/generated/models";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { numberParser } from "utils/numberParser";

export type MicroAdjustmentsCustomPillProps = {
  // Tried to remove these any's - very difficult
  row: any;
  column: any;
  value: string;
};

const MicroAdjustmentsCustomPill: FC<MicroAdjustmentsCustomPillProps> = ({
  row,
  column,
  value,
}) => {
  const [newCellValue, setNewCellValue] = useState<string | null>(null);
  const [percentageChange, setPercentageChange] = useState<string | null>(null);

  const type =
    row.subRows && row.subRows.length > 0 ? row.subRows[0].type : null;
  const productKey = column.id;
  const originalCellValue: string = value;
  const dataStructureKey = row.dataStructureKey;

  const calculateChipValues = () => {
    if (row.subRows[0] && productKey) {
      const rowValue = row[productKey as string];
      const subRowValue = row.subRows[0][productKey as string];

      const rowValueAsNumber: number = numberParser.parse(rowValue);

      const subRowValueAsNumber: number = numberParser.parse(subRowValue);

      const subRowValueAsString: string = subRowValue as string;

      if (subRowValueAsString === AppConstant.emptyCell) {
        setNewCellValue(null);
      } else if (
        rowValueAsNumber !== subRowValueAsNumber &&
        subRowValueAsString !== AppConstant.emptyCell
      ) {
        if (type === UserDataTypes.percentage && originalCellValue) {
          const _newCellValue =
            rowValueAsNumber * (1 + subRowValueAsNumber / 100);

          setNewCellValue(formatNumber(_newCellValue, dataStructureKey));
          setPercentageChange(subRowValueAsNumber.toString());
        } else if (type === UserDataTypes.new_value) {
          const _percentageChange: number = parseFloat(
            (
              ((subRowValueAsNumber - rowValueAsNumber) / rowValueAsNumber) *
              100
            ).toFixed(2),
          );
          setNewCellValue(formatNumber(subRowValueAsNumber, dataStructureKey));
          setPercentageChange(_percentageChange.toString());
        }
      }
    }
  };

  useEffect(calculateChipValues, [
    row,
    column,
    value,
    productKey,
    type,
    originalCellValue,
    dataStructureKey,
  ]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        data-testid="custom-pill-box"
      >
        <Box mr={1}>
          <Typography variant="body2" data-testid="original-value">
            {originalCellValue}
          </Typography>
        </Box>
        {newCellValue !== null ? (
          <Box data-testid="adjusted-value-box">
            <Chip
              label={
                parseFloat(percentageChange || "0") !== Infinity
                  ? `${newCellValue} (${formatNumber(percentageChange, dataStructureKey)}%)`
                  : newCellValue
              }
              sx={{
                backgroundColor: "#C3D3E2 !important",
                color: "#40576D",
              }}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default MicroAdjustmentsCustomPill;
