import { Alert, Snackbar } from "@mui/material";
import { createContext, ReactNode, useState } from "react";
import { SnackbarStates } from "./type";

type NotificationObj = {
  open: boolean;
  message?: string;
  state?: SnackbarStates;
  action?: JSX.Element;
  vertical?: "top" | "bottom";
  horizontal?: "right" | "left" | "center";
};

const initialState: NotificationObj = {
  open: false,
  message: "",
  state: "info",
};

type Context = {
  setNotificationState(state: NotificationObj): void;
};

export const NotificationsContext = createContext({} as Context);

export const NotificationProvider = (props: {
  children: JSX.Element | ReactNode;
}) => {
  const [notificationState, setNotificationState] =
    useState<NotificationObj>(initialState);

  return (
    <NotificationsContext.Provider value={{ setNotificationState }}>
      {props.children}
      <Snackbar
        data-testid="notification-box"
        sx={{ zIndex: 9999 }}
        anchorOrigin={{
          vertical: notificationState.vertical ?? "top",
          horizontal: notificationState.horizontal ?? "center",
        }}
        open={notificationState.open}
        autoHideDuration={5000}
        onClose={() =>
          setNotificationState((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        <Alert
          action={notificationState.action}
          severity={notificationState.state}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </NotificationsContext.Provider>
  );
};
