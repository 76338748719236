import { Box } from "@mui/material";
import AccountMenu from "../AccountMenu/AccountMenu";
import pwcLogo from "../../../assets/images/PwC_PEF_Logotype.svg";
import { useTranslation } from "react-i18next";
import ReportIssue from "../ReportIssue/ReportIssue";

export default function Header() {
  const { t } = useTranslation("common");
  return (
    <Box className="header" data-testid={"header"}>
      <Box>
        <img
          src={pwcLogo as string}
          alt={t("appLogotypeAltText")}
          loading="lazy"
          style={{ minHeight: "26px" }}
        />
      </Box>
      <Box className="settings">
        <ReportIssue />
        <AccountMenu />
      </Box>
    </Box>
  );
}
