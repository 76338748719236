import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../../../MicroAdjustmentsTable/types";

export const BodyAndLidPackagingMetrics: ProductsEditableColumnConfigItem[] = [
  {
    field_key: "body_weight",
    label: "micro:packagingSection.table.body_weight",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "body_recycled_content",
    label: "micro:packagingSection.table.body_recycled_content",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "body_recovery_rate",
    label: "micro:packagingSection.table.body_recovery_rate",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "lid_weight",
    label: "micro:packagingSection.table.lid_weight",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "lid_recycled_content",
    label: "micro:packagingSection.table.lid_recycled_content",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "lid_recovery_rate",
    label: "micro:packagingSection.table.lid_recovery_rate",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field_key: "net_loss_rate",
    label: "micro:packagingSection.table.net_loss_rate",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
];
