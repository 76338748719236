import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import SelectDropdown from "../../SelectDropdown/SelectDropdown";
import { SearchBar } from "../../SearchBar";

export type ProductSelectionModalSearchAndDropdownsProps = {
  skusYearList?: number[];
  skusBrandList?: string[];
  skusBrandGroupList?: string[];
  skusCategoryList?: string[];
  skusPackSizeList?: number[];
  skusPackTypeList?: string[];
  skusCountryList?: string[];
  skusMultipackList?: number[];
  handleSearch?: (searchValue: string) => void;
  handleSearchFilter?: (data: any) => void;
};

export type ProductSelectionModalSearchAndDropdownsDataType = {
  year: number[];
  brand: string[];
  brandGroup: string[];
  category: string[];
  packSize: number[];
  packType: string[];
  countryDisplayName: string[];
  multipack: number[];
};

export function ProductSelectionModalSearchAndDropdowns({
  skusYearList,
  skusBrandList,
  skusBrandGroupList,
  skusCategoryList,
  skusPackSizeList,
  skusPackTypeList,
  skusCountryList,
  skusMultipackList,
  handleSearch,
  handleSearchFilter,
}: ProductSelectionModalSearchAndDropdownsProps) {
  const { t } = useTranslation(["micro", "common"]);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedBrandGroups, setSelectedBrandGroups] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPackSizes, setSelectedPackSizes] = useState<string[]>([]);
  const [selectedPackTypes, setSelectedPackTypes] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedMultipacks, setSelectedMultipacks] = useState<string[]>([]);

  useEffect(() => {
    if (
      handleSearchFilter &&
      skusYearList &&
      skusBrandList &&
      skusBrandGroupList &&
      skusCategoryList &&
      skusPackSizeList &&
      skusPackTypeList &&
      skusCountryList &&
      skusMultipackList
    ) {
      const data: ProductSelectionModalSearchAndDropdownsDataType = {
        year: skusYearList,
        brand: skusBrandList,
        brandGroup: skusBrandGroupList,
        category: skusCategoryList,
        packSize: skusPackSizeList,
        packType: skusPackTypeList,
        countryDisplayName: skusCountryList,
        multipack: skusMultipackList,
      };
      handleSearchFilter(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleSearchFilter) {
      handleSearchFilter({
        year: selectedYears.map(Number),
        brand: selectedBrands,
        brandGroup: selectedBrandGroups,
        category: selectedCategories,
        packSize: selectedPackSizes.map(Number),
        packType: selectedPackTypes,
        countryDisplayName: selectedCountries,
        multipack: selectedMultipacks.map(Number),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBrands,
    selectedBrandGroups,
    selectedCategories,
    selectedPackSizes,
    selectedPackTypes,
    selectedYears,
    selectedCountries,
    selectedMultipacks,
  ]);

  function handleClear() {
    setSelectedYears([]);
    setSelectedBrands([]);
    setSelectedBrandGroups([]);
    setSelectedCategories([]);
    setSelectedPackSizes([]);
    setSelectedPackTypes([]);
    setSelectedCountries([]);
    setSelectedMultipacks([]);
  }

  function sortNumericallyAscending(x: number, y: number) {
    return x - y;
  }

  return (
    <>
      <Box mb={1}>
        <SearchBar
          placeHolder={t("micro:productsSection.selectProductModal.searchSKU")}
          handleSearch={handleSearch}
        />
      </Box>
      <Box mb={2}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            {skusYearList && (
              <SelectDropdown
                listItems={skusYearList.sort(sortNumericallyAscending)}
                onSave={setSelectedYears}
                savedSelectedItems={selectedYears}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.year",
                )}
                compact
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusCountryList && (
              <SelectDropdown
                listItems={skusCountryList.sort().map(String)}
                onSave={setSelectedCountries}
                savedSelectedItems={selectedCountries}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.country",
                )}
                compact
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusCategoryList && (
              <SelectDropdown
                listItems={skusCategoryList.sort()}
                onSave={setSelectedCategories}
                savedSelectedItems={selectedCategories}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.category",
                )}
                compact
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusBrandGroupList && (
              <SelectDropdown
                listItems={skusBrandGroupList.sort()}
                onSave={setSelectedBrandGroups}
                savedSelectedItems={selectedBrandGroups}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.brandGroup",
                )}
                compact
                virtualiseList={true}
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusBrandList && (
              <SelectDropdown
                listItems={skusBrandList.sort()}
                onSave={setSelectedBrands}
                savedSelectedItems={selectedBrands}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.brand",
                )}
                compact
                virtualiseList={true}
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusPackTypeList && (
              <SelectDropdown
                listItems={skusPackTypeList.sort().map(String)}
                onSave={setSelectedPackTypes}
                savedSelectedItems={selectedPackTypes}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.packType",
                )}
                compact
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusPackSizeList && (
              <SelectDropdown
                listItems={skusPackSizeList
                  .sort(sortNumericallyAscending)
                  .map(String)}
                onSave={setSelectedPackSizes}
                savedSelectedItems={selectedPackSizes}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.packSize",
                )}
                compact
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
          <Grid item xs={6}>
            {skusMultipackList && (
              <SelectDropdown
                listItems={skusMultipackList
                  .sort(sortNumericallyAscending)
                  .map(String)}
                onSave={setSelectedMultipacks}
                savedSelectedItems={selectedMultipacks}
                title={t(
                  "micro:productsSection.selectProductModal.filterLabel.multipack",
                )}
                compact
                requireSave={true}
              ></SelectDropdown>
            )}
          </Grid>
        </Grid>
        <Stack
          direction="row"
          spacing={2}
          margin="auto"
          justifyContent="flex-end"
        >
          <Button
            variant="text"
            onClick={handleClear}
            disabled={
              selectedYears.length === 0 &&
              selectedBrands.length === 0 &&
              selectedBrandGroups.length === 0 &&
              selectedMultipacks.length === 0 &&
              selectedCategories.length === 0 &&
              selectedPackSizes.length === 0 &&
              selectedPackTypes.length === 0 &&
              selectedCountries.length === 0
            }
          >
            {t("common:actions.clearFilter")}
          </Button>
        </Stack>
      </Box>
    </>
  );
}
