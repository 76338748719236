import { Box, Typography, Link, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "./Footer.scss";
import "../../sections/RootPage/RootPage.scss";
import { useTermsContext } from "features/terms-and-conditions/components/TermsContext";
import { AppConstant } from "constants/AppConstant";

type FooterProps = {
  applicationVersion: string | undefined;
  masterDatasetVersion: string | undefined;
  content: string;
};

export default function Footer({
  applicationVersion,
  masterDatasetVersion,
  content,
}: FooterProps) {
  const { isTermsAccepted } = useTermsContext();
  const [appVersion, setAppVersion] = useState<string | undefined>(
    () =>
      localStorage.getItem(AppConstant.localStorageKeys.appVersion) ||
      applicationVersion,
  );
  const [masterDataVersion, setMasterDataVersion] = useState<
    string | undefined
  >(() => {
    const storedVersion = localStorage.getItem(
      AppConstant.localStorageKeys.masterDataVersion,
    );
    return storedVersion ? storedVersion : masterDatasetVersion;
  });

  const contentLinks = [
    {
      name: "www.pwc.com/structure",
      href: "https://www.pwc.com/structure",
    },
  ];

  const footerLinks = [
    {
      href: "https://www.pwc.co.uk/who-we-are/legal-disclaimer.html",
      labelTranslationKey: "footer.legalDisclaimer",
    },
    {
      href: "/legal/terms-of-use",
      labelTranslationKey: "footer.terms",
    },
    {
      href: "/legal/privacy-statement",
      labelTranslationKey: "footer.privacy",
    },
    {
      href: "/legal/cookie-statement",
      labelTranslationKey: "footer.cookies",
    },
  ];

  const { t } = useTranslation("common");

  useEffect((): void => {
    if (isTermsAccepted) {
      if (appVersion !== applicationVersion) {
        localStorage.setItem(
          AppConstant.localStorageKeys.appVersion,
          applicationVersion ?? "",
        );
        setAppVersion(applicationVersion);
      }
      if (masterDataVersion !== (masterDatasetVersion ?? 0)) {
        localStorage.setItem(
          AppConstant.localStorageKeys.masterDataVersion,
          (masterDatasetVersion ?? 0).toString(),
        );
        setMasterDataVersion(masterDatasetVersion);
      }
    }
  }, [
    isTermsAccepted,
    applicationVersion,
    masterDatasetVersion,
    appVersion,
    masterDataVersion,
  ]);

  return (
    <Box className="footer">
      <Grid container justifyContent="space-between">
        <Grid item container rowSpacing={1} flexDirection="column" width="auto">
          <Grid item container columnSpacing={2} alignItems="center">
            {footerLinks.map((link, i) => (
              <Grid
                item
                key={link.labelTranslationKey}
                sx={
                  i === footerLinks.length - 1
                    ? {}
                    : { borderRight: "1px solid #cbcbcb" }
                }
                px={2}
              >
                <Link href={link.href} underline="none" className="footer-text">
                  {t(link.labelTranslationKey)}
                </Link>
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <Typography className="footer-text">
              {formatContent(content, contentLinks) ||
                "© 2024 PwC. All rights reserved."}
            </Typography>
          </Grid>
        </Grid>

        <Grid item data-testid={"app-version-area"}>
          {appVersion && masterDataVersion ? (
            <Typography className="footer-text">{`${t("footer.mdVersion")}${masterDataVersion} · ${t("footer.toolVersion")}${appVersion}`}</Typography>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

function formatContent(
  content: string,
  links: { name: string; href: string; target?: string }[],
): JSX.Element {
  return (
    <>
      {content.split(" ").map((word, index) => {
        const link:
          | {
              name: string;
              href: string;
              target?: string | undefined;
            }
          | undefined = links.find((link) => link.name === word);
        return link ? (
          <Link href={link.href} key={`footer_${index}`} underline="hover">
            {link.name}
          </Link>
        ) : (
          ` ${word} `
        );
      })}
    </>
  );
}
