import { Box, Button, Typography } from "@mui/material";
import "../../../assets/styles/base/heading.scss";
import { usePermissions } from "core/navigation/usePermissions";
import { Grid } from "@mui/material";
import { Permission } from "core/navigation/permission";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import { IconName } from "components/common/CustomIcon/types";

export type OptionCardProps = {
  icon: IconName;
  title: string;
  description: string;
  actionButtonLabel: string;
  onActionButtonClick: Function;
  actionButtonAriaLabel: string;
  permission?: Permission;
};

export function OptionCard({
  icon,
  title,
  description,
  actionButtonLabel,
  onActionButtonClick,
  actionButtonAriaLabel,
  permission,
}: OptionCardProps) {
  const { useHasPermission } = usePermissions();
  const hasPermission = useHasPermission(permission);

  return (
    <>
      {hasPermission && (
        <Grid item md={6} xs={12} p={2} key={title}>
          <Box
            key={permission}
            display="flex"
            flexDirection="column"
            height="100%"
            data-testid="option-card-box"
          >
            <Box tabIndex={0} height="100%">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "36.5px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CustomIcon
                    name={icon}
                    width={"32px"}
                    height={"28px"}
                    fill={"#d04a02"}
                  />
                  <Typography className="header-H2" sx={{ marginLeft: 1 }}>
                    {title}
                  </Typography>
                </Box>
                <Button
                  sx={{ width: "auto" }}
                  variant="outlined"
                  onClick={() => {
                    onActionButtonClick();
                  }}
                  aria-label={actionButtonAriaLabel}
                >
                  {actionButtonLabel}
                </Button>
              </Box>
              <Box
                pt={3}
                sx={{
                  height: "calc(100% - 36.5px)",
                }}
              >
                <Box
                  p={2}
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid",
                    borderColor: "#CBCBCB",
                    height: "100%",
                  }}
                >
                  <Typography>{description}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
}
