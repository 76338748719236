import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const MacroAdjustmentStepThree = () => {
  const { t } = useTranslation("macro");
  return (
    <Box>
      <Box mb={1}>
        <Typography className="header-H2 text-ellipsis">
          {t("adjustmentsPage.wizard.stepThree.title")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepThree.description")}
        </Typography>
      </Box>
    </Box>
  );
};
