import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./PolicyPage.scss";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import {
  useGetCookiesPolicyLegalCookieStatementGet,
  useGetPrivacyPolicyLegalPrivacyStatementGet,
  useGetTermsAndConditionsLegalTermsOfUseGet,
} from "orval/generated/endpoint";
import { AxiosError } from "axios";

const PolicyPage = () => {
  const { policyType } = useParams();
  const { t } = useTranslation("home");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const { data: privacyPolicyData, error: privacyPolicyDataError } =
    useGetPrivacyPolicyLegalPrivacyStatementGet({
      query: {
        enabled: policyType === "privacy-statement",
      },
    });

  const { data: cookiesPolicyData, error: cookiesPolicyDataError } =
    useGetCookiesPolicyLegalCookieStatementGet({
      query: {
        enabled: policyType === "cookie-statement",
      },
    });

  const { data: termsOfUseData, error: termsOfUseDataError } =
    useGetTermsAndConditionsLegalTermsOfUseGet({
      query: {
        enabled: policyType === "terms-of-use",
      },
    });

  useEffect(() => {
    switch (policyType) {
      case "terms-of-use":
        setTitle(t("policies.title.terms-of-use"));
        termsOfUseData && setContent(termsOfUseData.content);
        break;
      case "disclaimer":
        setTitle(t("policies.title.disclaimer"));
        break;
      case "cookie-statement":
        setTitle(t("policies.title.cookie-statement"));
        cookiesPolicyData && setContent(cookiesPolicyData.content);
        break;
      case "privacy-statement":
        setTitle(t("policies.title.privacy-statement"));
        privacyPolicyData && setContent(privacyPolicyData.content);
        break;
    }
  }, [termsOfUseData, cookiesPolicyData, privacyPolicyData, policyType, t]);

  useEffect(() => {
    if (
      privacyPolicyDataError ||
      cookiesPolicyDataError ||
      termsOfUseDataError
    ) {
      showSnackbar(
        (privacyPolicyDataError as AxiosError<unknown, any>) ||
          cookiesPolicyDataError ||
          termsOfUseDataError,
        "error",
      );
    }
  }, [
    privacyPolicyDataError,
    cookiesPolicyDataError,
    termsOfUseDataError,
    showSnackbar,
  ]);

  return (
    <Box>
      <Button
        variant="text"
        onClick={() => navigate(`/home`)}
        startIcon={<ArrowBackIosSharpIcon />}
      >
        {t("policies.back-to-home")}
      </Button>
      <Box mb={6}>
        <Box mt={2}>
          <Typography className="header-H1">{title}</Typography>
        </Box>
        <Box className="markdown-content policy-page" mt={2}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1(props) {
                return (
                  <Typography
                    className="header-H1"
                    sx={{ marginBottom: 1, marginTop: 3 }}
                  >
                    {props.children}
                  </Typography>
                );
              },
              h2(props) {
                return (
                  <Typography
                    className="header-H2"
                    sx={{ marginBottom: 1, marginTop: 3 }}
                  >
                    {props.children}
                  </Typography>
                );
              },
              h3(props) {
                return (
                  <Typography
                    className="header-H3"
                    sx={{ marginBottom: 1, marginTop: 2 }}
                  >
                    {props.children}
                  </Typography>
                );
              },
              p(props) {
                return <Typography>{props.children}</Typography>;
              },
              ol(props) {
                return <ol>{props.children}</ol>;
              },
              li(props) {
                return (
                  <li className="markdown-content-li">{props.children}</li>
                );
              },
              strong(props) {
                return (
                  <span className="markdown-content-strong">
                    {props.children}
                  </span>
                );
              },
            }}
          >
            {content.toString()}
          </ReactMarkdown>
        </Box>
      </Box>
    </Box>
  );
};

export default PolicyPage;
