export const HTTPConstants = {
  refreshTokenURL: "/oauth2/token",
};

export enum STATUS_CODES {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  BAD_GATEWAY = 502,
  UNAVAILABLE = 503,
  INTERNAL_SERVER_ERROR = 500,
}
