export const AuthRoutesConfig = {
  idamLogin: "/auth",
  idamLogout: "/auth/logout",
};

export const AuthSessionStorageKeys = {
  rememberMe: "rememberMe",
  email: "email",
  accessToken: "accessToken",
  idToken: "idToken",
  refreshToken: "refreshToken",
  userInfo: "userInfo",
};
