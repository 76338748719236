import { RouteObject } from "react-router-dom";
import { SupportingPagesRoutesConfig } from "./config";
import { AccessDenied } from "../routes/AccessDenied";
import { RootPageHeaderOnly } from "../routes/RootPageHeaderOnly";

export const SupportingPagesRoutes: RouteObject = {
  path: SupportingPagesRoutesConfig.accessDenied,
  element: <RootPageHeaderOnly />,
  children: [
    { index: true, element: <AccessDenied /> },
  ],
};
