import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";

export interface AuthMessageProps {
  message: string;
  buttonText: string;
  onButtonClick: () => void;
  isLoading: boolean | undefined;
  disableButton?: boolean;
}

const AuthMessage: FC<AuthMessageProps> = ({
  message,
  buttonText,
  onButtonClick,
  isLoading,
  disableButton,
}) => (
  <Box>
    <Stack mt={2} spacing={5}>
      <Typography variant="h2" sx={{ fontSize: "3rem", fontWeight: 300 }}>
        {message}
      </Typography>
      <Button
        onClick={onButtonClick}
        variant="contained"
        sx={{ maxWidth: "300px" }}
        disabled={disableButton}
      >
        {buttonText}
        {isLoading && (
          <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
        )}
      </Button>
    </Stack>
  </Box>
);

export default AuthMessage;
