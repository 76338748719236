import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { MetadataActiveAlert } from "orval/generated/models";
import { useTermsContext } from "features/terms-and-conditions/components/TermsContext";
import { useGetAppInfoMetadataGet } from "orval/generated/endpoint";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { AxiosError } from "axios";

interface MetadataContextType {
  activeAlert: MetadataActiveAlert | null;
  setActiveAlert: Dispatch<SetStateAction<MetadataActiveAlert>>;
  applicationVersion: string | undefined;
  setApplicationVersion: Dispatch<SetStateAction<string | undefined>>;
  masterDatasetVersion: string | undefined;
  setMasterDatasetVersion: Dispatch<SetStateAction<string | undefined>>;
}

export const MetadataContext = createContext<MetadataContextType | undefined>(
  undefined,
);

export const MetadataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeAlert, setActiveAlert] = useState<MetadataActiveAlert | null>(
    null,
  );
  const [applicationVersion, setApplicationVersion] = useState<
    string | undefined
  >(undefined);
  const [masterDatasetVersion, setMasterDatasetVersion] = useState<
    string | undefined
  >(undefined);
  const { isTermsAccepted } = useTermsContext();
  const showSnackbar = useSnackbar();
  const [shouldFetch, setShouldFetch] = useState(false);

  // NB: This has been done this way because if we don't wait for isTermsAccepted to be true,
  // then we always have failed metadata calls in the network tab
  useEffect(() => {
    setShouldFetch(!!isTermsAccepted);
  }, [isTermsAccepted]);
  const { data: metadata, error: metaDataError } = useGetAppInfoMetadataGet({
    query: {
      enabled: shouldFetch,
    },
  });

  useEffect(() => {
    if (metadata) {
      setActiveAlert(metadata.active_alert);
      setApplicationVersion(metadata.application_version);
      setMasterDatasetVersion(metadata.master_dataset_version);
    }
    if (metaDataError) {
      showSnackbar(metaDataError as AxiosError<unknown, any>, "error");
    }
  }, [metaDataError, metadata, showSnackbar]);

  return (
    <MetadataContext.Provider
      value={{
        activeAlert,
        setActiveAlert,
        applicationVersion,
        setApplicationVersion,
        masterDatasetVersion,
        setMasterDatasetVersion,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

export const useMetadata = () => {
  const context = useContext(MetadataContext);
  if (!context) {
    throw new Error("useMetadata must be used within a MetadataProvider");
  }
  return context;
};
