import { Chart } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { TableResultsFilterDataType } from "../../ScenarioResults/types";

interface CustomOptions extends Options {
  units: TableResultsFilterDataType;
}

export const TotalLabelsPlugin = {
  id: "totalLabels",
  afterDatasetsDraw(chart: Chart, args: Object, pluginOptions: CustomOptions) {
    const { ctx } = chart;
    ctx.font = "bold 12px sans-serif";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";

    const { units } = pluginOptions;

    chart?.data?.datasets?.[0]?.data?.forEach((datapoint, index: number) => {
      const stackLabels: string[] = ["base", "glide", "scenario"];
      stackLabels.forEach((stackLabel) => {
        const datasetArray: number[] = [];
        chart.data.datasets.forEach((dataset) => {
          if (dataset.stack === stackLabel) {
            datasetArray.push(dataset.data[index] as number);
          }
        });

        const total = datasetArray.reduce((sum, values) => sum + values, 0);

        if (datasetArray.length !== 0 && total !== 0) {
          // .findLastIndex not yet supported to find x,y of top stacked section
          const lastDataPointIndex =
            chart.data.datasets.length -
            1 -
            [...chart.data.datasets]
              .reverse()
              .findIndex((x) => x.stack === stackLabel);
          ctx.fillText(
            formatNumber(total, units),
            chart.getDatasetMeta(lastDataPointIndex).data[index].x,
            chart.getDatasetMeta(lastDataPointIndex).data[index].y - 5,
          );
        }
      });
    });
  },
};
