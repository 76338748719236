import { Chart } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";

export const StackAxisLabelsPlugin = {
  id: "topLabels",
  afterDatasetsDraw(chart: Chart, args: Object, pluginOptions: Options) {
    const { ctx } = chart;

    // Pull out the original and adjusted data points for summing
    chart?.data?.datasets?.[0]?.data?.forEach((datapoint, index: number) => {
      ctx.fillStyle = "#6A6A6A";
      ctx.textAlign = "center";

      // .findLastIndex not yet supported to find x,y of top stacked section (usually CDE)
      const firstOriginalDataPointIndex = [...chart.data.datasets].findIndex(
        (x) => x.stack === "Original",
      );
      if (firstOriginalDataPointIndex !== -1) {
        ctx.fillText(
          "Original",
          chart.getDatasetMeta(firstOriginalDataPointIndex).data[index].x,
          chart.chartArea.bottom + 15,
        );
      }

      const firstAdjustedDataPointIndex = [...chart.data.datasets].findIndex(
        (x) => x.stack === "Adjusted",
      );
      if (firstAdjustedDataPointIndex !== -1) {
        ctx.fillText(
          "Adjusted",
          chart.getDatasetMeta(firstAdjustedDataPointIndex).data[index].x,
          chart.chartArea.bottom + 15,
        );
      }
    });
  },
};
