import { IconButton, Tooltip, Typography } from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Box from "@mui/material/Box";
import { Product } from "orval/generated/models";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";

export type SelectedProductRowProps = {
  item: Product;
  handleRemoveSelectedProduct: (id: string) => void;
};

export function SelectedProductRow({
  item,
  handleRemoveSelectedProduct,
}: SelectedProductRowProps) {
  const { t } = useTranslation("micro");

  return (
    <Box
      key={`${item.custom_display_name ?? item.product_name}-${item.guid || ""}`}
      width={"90%"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      data-testid="selected-product-row"
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"auto"}
        alignItems={"center"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Typography>
            {item?.custom_display_name || item?.product_name}
          </Typography>
        </Box>
      </Box>
      {!item.guid ? (
        <IconButton
          data-testid={`delete-btn`}
          aria-label="delete"
          sx={{ aspectRatio: 1, marginLeft: "auto" }}
          onClick={() => {
            handleRemoveSelectedProduct(
              item.custom_display_name || item.product_name,
            );
          }}
        >
          <CloseSharpIcon sx={{ fontSize: 20 }} />
        </IconButton>
      ) : (
        <Box sx={{ aspectRatio: 1, marginLeft: "auto" }} mr={1}>
          <Tooltip title={t("productsSection.selectProductModal.tooltip")}>
            <LockIcon
              sx={(theme) => ({ color: theme.palette.text.disabled })}
              data-testid="lock-icon"
            />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
