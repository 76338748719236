import { Box } from "@mui/material";
import Header from "../../../components/sections/Header/Header";
import { Outlet } from "react-router-dom";
import "../../../components/sections/RootPage/RootPage.scss";

export function RootPageHeaderOnly() {
  return (
    <Box className="root-page">
      <Header />
      <Box className="content">
        <Outlet />
      </Box>
    </Box>
  );
}
