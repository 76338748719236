// https://stackoverflow.com/a/78941643
// https://observablehq.com/@mbostock/localized-number-parsing
class NumberParser {
  private readonly _minusSign: RegExp;
  private _group: RegExp;
  private _decimal: RegExp;
  private _numeral: RegExp;
  private _index: (d: string) => string;

  constructor(locale?: string) {
    locale =
      locale || Intl.DateTimeFormat().resolvedOptions().locale || "en-GB";
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(-12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));

    const minusSignPart = parts.find((d) => d.type === "minusSign");
    const groupPart = parts.find((d) => d.type === "group");
    const decimalPart = parts.find((d) => d.type === "decimal");

    if (!minusSignPart || !groupPart || !decimalPart) {
      throw new Error("Locale data is missing required parts.");
    }

    this._minusSign = new RegExp(`[${minusSignPart.value}]`);
    this._group = new RegExp(`[${groupPart.value}]`, "g");
    this._decimal = new RegExp(`[${decimalPart.value}]`);
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d: string) => index.get(d)?.toString() ?? NaN.toString(); // This feels a bit hacky but it works
  }

  parse(string: string): number {
    if (string.trim() === "") {
      return NaN;
    }
    const DIRECTION_MARK = /\u061c|\u200e/g;
    return +string
      .trim()
      .replace(DIRECTION_MARK, "")
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index)
      .replace(this._minusSign, "-");
  }
}

const numberParser = new NumberParser();
export { numberParser, NumberParser };
