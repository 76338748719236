import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../../../MicroAdjustmentsTable/types";

export const ServingSizeMetrics: ProductsEditableColumnConfigItem[] = [
  {
    field_key: "serving_size",
    label: "micro:servingSizeSection.table.servingSize",
    defaultUnit: MicroUnits.OVERRIDE,
  },
];
