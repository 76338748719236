import { Typography, Box, Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import React from "react";

export interface ConfirmationModalProps {
  title: string;
  description: string;
  actionTitle: string;
  confirmAction: () => void;
  cancelAction: (event?: React.MouseEvent<HTMLElement>) => void;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation(["micro", "common"]);

  return (
    <Box maxWidth={500}>
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label={t("common:actions.cancel")}
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={props.cancelAction}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box paddingX={5} paddingBottom={4}>
        <Typography textAlign="center" variant="h4" marginBottom={3}>
          {props.title}
        </Typography>
        <Typography textAlign="center" variant="body2" marginBottom={3}>
          {props.description}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={props.cancelAction}
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={props.confirmAction}
            data-testid="confirm-action-button"
          >
            {props.actionTitle}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationModal;
