import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { CustomTabPanel, CustomTabs } from "components/common";
import { scenarioResultsTabs } from "../../constants";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { ResultsTabSection } from "./ResultsTabSection/ResultsTabSection";
import { DataVisualisationTabSection } from "./DataVisualisationTabSection";

type ScenarioResultsProps = {
  scenario: ViewMacroScenarioViewModel;
  disabled: boolean;
};

export function ScenarioResults({ scenario, disabled }: ScenarioResultsProps) {
  const { t } = useTranslation("macro");
  const [value, setValue] = useState(0);

  return (
    <Box pt={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" fontWeight={500}>
          {t("createScenarioSection.scenarioResultsHeading")}
        </Typography>
      </Box>
      <Box>
        <Box borderBottom={1} borderColor="divider">
          <CustomTabs
            tabList={scenarioResultsTabs.tabs}
            value={value}
            setValue={setValue}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ResultsTabSection disabled={disabled} scenario={scenario} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DataVisualisationTabSection
            disabled={disabled}
            scenario={scenario}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
