export enum OptionalMetricsTableResultsType {
  BY_PACK_TYPE = "by_pack_type",
  BY_CATEGORY = "by_category",
  BY_PILLAR = "by_pillar",
}

export enum OptionalMetricsTableResultsFilterDataType {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
}

export type OptionalMetricsTableDataItem = {
  [x: string]: string;
  base: string;
  glide: string;
  scenario: string;
};
