import { RouteObject } from "react-router-dom";
import { MicroToolRoutesConfig } from "./config";
import MicroToolScenarioPage from "../routes/MicroToolScenarioPage/MicroToolScenarioPage";
import MicroViewAllScenariosPage from "../routes/MicroViewAllScenariosPage/MicroViewAllScenariosPage";

export const MicroToolRoutes: RouteObject = {
  path: MicroToolRoutesConfig.productLevelAnalytics,
  children: [
    {
      index: true,
      element: <MicroViewAllScenariosPage />
    },
    {
      path: MicroToolRoutesConfig.scenarioPage,
      element: <MicroToolScenarioPage />,
    },
  ],
};
