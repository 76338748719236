import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsUserAuthenticated } from "../Auth";

function useCheckAuthState(isSuccess: string, isFailure: string) {
  const navigate = useNavigate();
  const isUserAuthenticated = useIsUserAuthenticated();

  const handleState = useCallback(
    (isAuthenticated: boolean) => {
      if (isAuthenticated) {
        navigate(isSuccess);
      } else {
        navigate(isFailure);
      }
    },
    [isSuccess, isFailure, navigate],
  );

  useEffect(() => {
    handleState(isUserAuthenticated);
  }, [isSuccess, isFailure, navigate, handleState, isUserAuthenticated]);

  return [isUserAuthenticated];
}

export default useCheckAuthState;
