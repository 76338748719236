import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, useState } from "react";

export type SearchBarProps = {
  placeHolder?: string;
  handleSearch?: (search: string) => void;
  disabled?: boolean;
};

export function SearchBar({
  placeHolder,
  handleSearch,
  disabled,
}: SearchBarProps) {
  const { t } = useTranslation("common");
  const [searchString, setSearchString] = useState("");

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    if (handleSearch) {
      handleSearch(e.target.value);
      setSearchString(e.target.value);
    }
  }

  return (
    <Box width={"100%"}>
      <TextField
        id="search"
        variant="outlined"
        fullWidth
        placeholder={placeHolder || t("labels.search")}
        onChange={(e) => handleChange(e)}
        autoComplete="search"
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        value={searchString}
        size="small"
        disabled={disabled}
        data-testid="search-bar"
      />
    </Box>
  );
}
