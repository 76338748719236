export enum MicroValidationErrors {
  InvalidNumber = "Value must be a valid number",
  GreaterThanZero = "Value must be greater than 0",
  GreaterThanOrEqualToZero = "Value must be greater than or equal to 0",
  BetweenZeroAndHundred = "Value must be between 0 and 100",
  GreaterThanZeroOrLessThanOrEqualToHundred = "Value must be greater than 0 and less than or equal 100.",
  BetweenZeroAndHundredInclusive = "Value must be between 0 and 100, inclusive.",
  GreaterThanNegativeHundred = "Value must be greater than -100",
  GreaterThanOrEqualToNegativeHundred = "Value must be greater than or equal to -100",
}
