import i18next from "i18next";
import { CombinedDataItem } from "../../ScenarioResults/types";
import pillarDeepDiveAccessorKeys from "../../ScenarioResults/pillarDeepDiveAccessorKeys";

function getPillarDeepDiveTableColumns() {
  let columns: {
    accessorKey: pillarDeepDiveAccessorKeys | string;
    header: string;
    id: pillarDeepDiveAccessorKeys | string;
    Cell?: (row: CombinedDataItem) => string | number | undefined;
  }[] = Object.values(pillarDeepDiveAccessorKeys).map((key) => {
    return {
      accessorKey: key,
      header: i18next.t(`common:emissionCategories.${key}`),
      id: key,
    };
  });

  // Create a single column for 'base', 'glide', and 'scenario'
  const baseOrGlideOrScenarioColumn = {
    accessorKey: "baseGlideScenario",
    header: "",
    id: "baseGlideScenario",
  };

  const categoryColumn = {
    accessorKey: "category",
    header: "",
    id: "category",
  };

  // Add the new column at the beginning of the columns array
  columns.unshift(baseOrGlideOrScenarioColumn);
  columns.unshift(categoryColumn);

  return columns;
}

export default getPillarDeepDiveTableColumns;
