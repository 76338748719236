import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { formatNumberOrEmpty } from "utils/formatNumber/formatNumber";
import { orderWithCDE } from "features/micro-tool/components/MicroEmissionsChart/ChartOrder";
import getItemName from "./getItemName";
import {
  OptionalMetricsTableDataItem,
  OptionalMetricsTableResultsFilterDataType,
  OptionalMetricsTableResultsType,
} from "../Types";

function getResultsOverviewTableData(
  type: OptionalMetricsTableResultsType,
  scenario: ViewMacroScenarioViewModel,
  dataType: OptionalMetricsTableResultsFilterDataType,
): OptionalMetricsTableDataItem[] {
  const dataArea = scenario.results?.[type];

  if (!dataArea) {
    return [];
  }

  const filteredDataArea = Object.entries(dataArea).map(([key, value]) => {
    return {
      [key]: value[dataType],
    };
  });

  const tableData: OptionalMetricsTableDataItem[] = filteredDataArea
    .map((item) => {
      const itemValues = Object.values(item)[0];
      if (!itemValues) return null;

      const base = formatNumberOrEmpty(
        type === "by_pillar" ? itemValues.base : itemValues.total?.base,
        dataType,
      );
      const glide = formatNumberOrEmpty(
        type === "by_pillar" ? itemValues.glide : itemValues.total?.glide,
        dataType,
      );
      const scenario = formatNumberOrEmpty(
        type === "by_pillar" ? itemValues.scenario : itemValues.total?.scenario,
        dataType,
      );

      const itemName = getItemName(item);

      return {
        [type]: itemName,
        base,
        glide,
        scenario,
      };
    })
    .filter((item): item is OptionalMetricsTableDataItem => item !== null);

  const totalsData = scenario.results?.by_pillar.total[dataType];
  const totalsObject = totalsData &&
    scenario.results?.by_pillar.total[dataType] && {
      [type]: "Total",
      base: formatNumberOrEmpty(totalsData.base, dataType),
      glide: formatNumberOrEmpty(totalsData.glide, dataType),
      scenario: formatNumberOrEmpty(totalsData.scenario, dataType),
    };

  if (totalsObject && !tableData.some((item) => item[type] === "Total")) {
    tableData.push(totalsObject);
  }

  tableData.sort((a, b) => {
    if (a && a[type] === "Total") return 1;
    if (b && b[type] === "Total") return -1;
    if (type === "by_pillar") {
      return (
        orderWithCDE.findIndex(
          (x) => b && b[type].toLowerCase() === x.toLowerCase(),
        ) -
        orderWithCDE.findIndex(
          (x) => a && a[type].toLowerCase() === x.toLowerCase(),
        )
      );
    } else {
      return a && b ? a[type].localeCompare(b[type]) : 0;
    }
  });

  return tableData;
}

export default getResultsOverviewTableData;
