export const BaseInputConfigs = [
  {
    key: "ingredients",
    label: "micro:resultsSection.table.ingredients",
  },
  {
    key: "packaging",
    label: "micro:resultsSection.table.packaging",
  },
  {
    key: "manufacturing",
    label: "micro:resultsSection.table.manufacturing",
  },
  {
    key: "logistics",
    label: "micro:resultsSection.table.logistics",
  },
  {
    key: "cde",
    label: "micro:resultsSection.table.cde",
  },
  {
    key: "total",
    label: "micro:resultsSection.table.total",
  },
] as const;
