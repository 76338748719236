import { useEffect, useState } from "react";
import { MenuItem, FormControl, Select, Box } from "@mui/material";
import { MRT_Row, MRT_RowData } from "material-react-table";

export type MicroColumnWithDropDownProps = {
  row: MRT_Row<MRT_RowData>;
  isEditing?: boolean;
};

export enum MicroUnitTypes {
  OVERRIDE = "new_value",
  PERCENTAGE = "percentage",
}

export function MicroColumnWithDropDown({
  row,
  isEditing,
}: MicroColumnWithDropDownProps) {
  const [value, setValue] = useState<MicroUnitTypes>(
    row.original.type || MicroUnitTypes.PERCENTAGE,
  );

  useEffect(() => {
    if (row.original.type) {
      setValue(row.original.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.original.type]);

  return (
    <>
      <Box alignSelf={"flex-start"}>
        <FormControl variant="standard" sx={{ ml: 2, pr: 2, width: "100%" }}>
          <Select
            variant="standard"
            labelId="column-with-dropdown-label"
            id="column-with-dropdown"
            value={value}
            onChange={(event) => {
              setValue(event.target.value as MicroUnitTypes);
              row.original.type = event.target.value;
            }}
            label=""
            disabled={!isEditing}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: !isEditing ? "currentColor !important" : "",
              },
            }}
          >
            <MenuItem value={MicroUnitTypes.PERCENTAGE}>% Change</MenuItem>
            <MenuItem value={MicroUnitTypes.OVERRIDE}>Override</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
