/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Product Emissions Forecaster
 * OpenAPI spec version: 3.1
 */

export type AlertStatusEnum =
  (typeof AlertStatusEnum)[keyof typeof AlertStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertStatusEnum = {
  information: "information",
  warning: "warning",
  success: "success",
  error: "error",
  "": "",
} as const;
