import {
  MacroResultsByCategory,
  MacroResultsByPackType,
} from "orval/generated/models";
import getSortedCategoryKeys from "./getSortedCategoryKeys";

function getTimeEvolutionLabels(
  result: MacroResultsByPackType | MacroResultsByCategory,
): string[] {
  if (!result) {
    return [];
  }

  return [...getSortedCategoryKeys(result)];
}

export default getTimeEvolutionLabels;
