const downloadBlob = (response: BlobPart, fileName: string, type?: string) => {
  const blob = new Blob([response], {
    type: type
      ? type
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};

export default downloadBlob;
