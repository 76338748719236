import { Box, Typography } from "@mui/material";
import "../NivoColours.css";
import { useTranslation } from "react-i18next";

export interface MekkoCustomLegendProps {
  keys: string[];
  chartColours: string[];
}

export const MekkoCustomLegend = ({
  keys,
  chartColours,
}: MekkoCustomLegendProps) => {
  const { t } = useTranslation("macro");
  function formatKey(key: string) {
    key = key.charAt(0).toUpperCase() + key.slice(1);
    return key.replaceAll("_", " ");
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      data-testid="mekko-custom-legend"
    >
      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          {t("mekkoAnalysis.xAxisLabel")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {keys.map((chartKey, index) => {
          return (
            <Box
              key={index}
              p={1}
              sx={{
                borderRadius: 1,
                flexShrink: 0,
                fontFamily: "sans-serif",
                fontSize: "12px",
                fill: "rgb(51, 51, 51)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: "5px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: chartColours[index],
                  }}
                  data-testid={`legend-colour-${index}`}
                ></Box>
                <span>{formatKey(chartKey)}</span>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
