import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Divider, IconButton, useMediaQuery } from "@mui/material";
import { ProductSelectionModalSearchAndDropdowns } from "./ProductSelectionModalSearchAndDropdowns/ProductSelectionModalSearchAndDropdowns";
import { emptyHandleSearchFilterProps, handleSearchFilterProps } from "./type";
import {
  Product,
  SKUMinimalViewModel,
  ViewMicroScenario,
} from "orval/generated/models";
import { ProductSelectionModalProductListPicker } from "./ProductSelectionModalProductListPicker/ProductSelectionModalProductListPicker";
import { ProductSelectionModalSelectedProductsDnD } from "./ProductSelectionModalSelectedProductsDnD/ProductSelectionModalSelectedProductsDnD";

export interface SKUModel extends SKUMinimalViewModel {
  guid?: string;
}

export type ProductSelectionModalProps = {
  closeModal: () => void;
  products?: Product[];
  skus: SKUModel[];
  scenarioDetails: ViewMicroScenario;
  handleAddProducts: Function;
};

export function ProductSelectionModal({
  closeModal,
  products,
  skus,
  scenarioDetails,
  handleAddProducts,
}: ProductSelectionModalProps) {
  const { t } = useTranslation(["micro", "common"]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredSkus, setFilteredSkus] = useState<SKUMinimalViewModel[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [changedProducts, setChangedProducts] = useState<Product[]>([]);
  const [skusYearList, setSkusYearList] = useState<number[]>([]);
  const [skusBrandList, setSkusBrandList] = useState<string[]>([]);
  const [skusBrandGroupList, setSkusBrandGroupList] = useState<string[]>([]);
  const [skusCategoryList, setSkusCategoryList] = useState<string[]>([]);
  const [skusPackSizeList, setSkusPackSizeList] = useState<number[]>([]);
  const [skusPackTypeList, setSkusPackTypeList] = useState<string[]>([]);
  const [skusCountryList, setSkusCountryList] = useState<string[]>([]);
  const [skusMultipackList, setSkusMultipackList] = useState<number[]>([]);
  const [searchedFilteredSkus, setSearchedFilteredSkus] = useState<
    SKUMinimalViewModel[]
  >([]);
  const productLimit: number = 20;

  useEffect(() => {
    if (products?.length && scenarioDetails.products.length > 0) {
      setSelectedProducts(products);
    }
    if (skus.length > 0) {
      generateAvailableFilterValues(emptyHandleSearchFilterProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback(
    (search: string): void => {
      const result =
        search.length > 0
          ? filteredSkus.filter(
              (item: SKUMinimalViewModel) =>
                item.product_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.brand?.toLowerCase().includes(search.toLowerCase()) ||
                item.category.toLowerCase().includes(search.toLowerCase()) ||
                item.country_display_name
                  .toLowerCase()
                  .includes(search.toLowerCase()),
            )
          : filteredSkus;
      setSearchedFilteredSkus(result);
      setSearchValue(search);
    },
    [filteredSkus],
  );

  useEffect(() => {
    setSearchedFilteredSkus(filteredSkus);
    handleSearch(searchValue);
  }, [filteredSkus, handleSearch, searchValue]);

  function handleSearchFilter(data: handleSearchFilterProps): void {
    const newFilteredSkus = skus.filter((sku) => {
      return (
        (!data.year ||
          data.year.length === 0 ||
          data.year.includes(sku.year)) &&
        (!data.brand ||
          data.brand.length === 0 ||
          data.brand.includes(sku.brand ?? "")) &&
        (!data.brandGroup ||
          data.brandGroup.length === 0 ||
          data.brandGroup.includes(sku.brand_group ?? "")) &&
        (!data.category ||
          data.category.length === 0 ||
          data.category.includes(sku.category)) &&
        (!data.packSize ||
          data.packSize.length === 0 ||
          data.packSize.includes(sku.pack_size)) &&
        (!data.packType ||
          data.packType.length === 0 ||
          data.packType.includes(sku.pack_type)) &&
        (!data.countryDisplayName ||
          data.countryDisplayName.length === 0 ||
          data.countryDisplayName.includes(sku.country_display_name)) &&
        (!data.multipack ||
          data.multipack.length === 0 ||
          data.multipack.includes(sku.multipack_size))
      );
    });
    generateAvailableFilterValues(data);
    setFilteredSkus(newFilteredSkus);
  }

  function onChangeSelectProduct(item: Product, checked: boolean): void {
    const matchingSku = skus.find(
      (sku) => sku.product_name === item.product_name,
    );
    if (matchingSku) {
      setChangedProducts((prevChangedProducts) => [
        ...prevChangedProducts,
        item,
      ]);
      if (checked) {
        setSelectedProducts((prevSelectedProducts) => {
          return [...prevSelectedProducts, item];
        });
      } else {
        setSelectedProducts((prevSelectedProducts) => {
          return prevSelectedProducts.filter(
            (prevSelectedItem) =>
              prevSelectedItem.product_name !== item.product_name,
          );
        });
      }
    }
  }

  function handleRemoveSelectedProduct(name: string): void {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((selectedItem) =>
        selectedItem.custom_display_name
          ? selectedItem.custom_display_name !== name
          : selectedItem.product_name !== name,
      ),
    );
  }

  function generateSkusWithoutGivenFilter(
    filter_type: keyof handleSearchFilterProps,
    data1: handleSearchFilterProps,
  ): SKUMinimalViewModel[] {
    const data = { ...data1 };
    data[filter_type] = [];

    return skus.filter((sku) => {
      return (
        (!data.year ||
          data.year.length === 0 ||
          data.year.includes(sku.year)) &&
        (!data.brand ||
          data.brand.length === 0 ||
          data.brand.includes(sku.brand ?? "")) &&
        (!data.brandGroup ||
          data.brandGroup.length === 0 ||
          data.brandGroup.includes(sku.brand_group ?? "")) &&
        (!data.category ||
          data.category.length === 0 ||
          data.category.includes(sku.category)) &&
        (!data.packSize ||
          data.packSize.length === 0 ||
          data.packSize.includes(sku.pack_size)) &&
        (!data.packType ||
          data.packType.length === 0 ||
          data.packType.includes(sku.pack_type)) &&
        (!data.countryDisplayName ||
          data.countryDisplayName.length === 0 ||
          data.countryDisplayName.includes(sku.country_display_name)) &&
        (!data.multipack ||
          data.multipack.length === 0 ||
          data.multipack.includes(sku.multipack_size))
      );
    });
  }

  function generateAvailableFilterValues(data: handleSearchFilterProps): void {
    const yearList: number[] = [];
    const brandList: string[] = [];
    const brandGroupList: string[] = [];
    const categoryList: string[] = [];
    const packSizeList: number[] = [];
    const packTypeList: string[] = [];
    const countryNameList: string[] = [];
    const multipackList: number[] = [];

    generateSkusWithoutGivenFilter("year", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (!yearList.includes(item.year ?? "") && item.year) {
          yearList.push(item.year);
        }
      },
    );

    generateSkusWithoutGivenFilter("brand", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (!brandList.includes(item.brand ?? "") && item.brand) {
          brandList.push(item.brand);
        }
      },
    );

    generateSkusWithoutGivenFilter("brandGroup", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (
          !brandGroupList.includes(item.brand_group ?? "") &&
          item.brand_group
        ) {
          brandGroupList.push(item.brand_group);
        }
      },
    );

    generateSkusWithoutGivenFilter("category", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (!categoryList.includes(item.category ?? "") && item.category) {
          categoryList.push(item.category);
        }
      },
    );

    generateSkusWithoutGivenFilter("packSize", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (!packSizeList.includes(item.pack_size ?? "") && item.pack_size) {
          packSizeList.push(item.pack_size);
        }
      },
    );

    generateSkusWithoutGivenFilter("packType", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (!packTypeList.includes(item.pack_type ?? "") && item.pack_type) {
          packTypeList.push(item.pack_type);
        }
      },
    );

    generateSkusWithoutGivenFilter("countryDisplayName", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (
          !countryNameList.includes(item.country_display_name ?? "") &&
          item.country_display_name
        ) {
          countryNameList.push(item.country_display_name);
        }
      },
    );

    generateSkusWithoutGivenFilter("multipack", data).forEach(
      (item: SKUMinimalViewModel) => {
        if (
          !multipackList.includes(item.multipack_size) &&
          item.multipack_size
        ) {
          multipackList.push(item.multipack_size);
        }
      },
    );

    setSkusYearList(yearList);
    setSkusBrandList(brandList);
    setSkusBrandGroupList(brandGroupList);
    setSkusCategoryList(categoryList);
    setSkusPackSizeList(packSizeList);
    setSkusPackTypeList(packTypeList);
    setSkusCountryList(countryNameList);
    setSkusMultipackList(multipackList);
  }

  function handleClearAllSelectedProducts(): void {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((product) => Boolean(product.guid)),
    );
  }

  const stackVertically = useMediaQuery("(max-width: 1200px)");

  return (
    <Box p={2} minWidth={"100%"} width={stackVertically ? "100%" : "74rem"}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={closeModal}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box mb={1}>
        <Typography textAlign="center" variant="h3" fontWeight="bold">
          {t("micro:productsSection.selectProductModal.title")}
        </Typography>
        <Typography textAlign="center" pt={3} px={4}>
          {t("micro:productsSection.selectProductModal.description")}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        gap={3}
        flexDirection={stackVertically ? "column" : "row"}
        p={3}
      >
        <Box flex={1}>
          <Box pl={1}>
            <Typography fontWeight="bold" mb={0.5}>
              {t("micro:productsSection.selectProductModal.searchProducts")}
            </Typography>
            <Typography textAlign="left" mb={1}>
              {t("micro:productsSection.selectProductModal.productLimit", {
                limit: productLimit,
              })}
            </Typography>
            <ProductSelectionModalSearchAndDropdowns
              skusYearList={skusYearList}
              skusBrandList={skusBrandList}
              skusBrandGroupList={skusBrandGroupList}
              skusCategoryList={skusCategoryList}
              skusPackSizeList={skusPackSizeList}
              skusPackTypeList={skusPackTypeList}
              skusCountryList={skusCountryList}
              skusMultipackList={skusMultipackList}
              handleSearch={handleSearch}
              handleSearchFilter={handleSearchFilter}
            />
          </Box>
          <Divider />
          <ProductSelectionModalProductListPicker
            filteredSkus={searchedFilteredSkus}
            selectedProducts={selectedProducts}
            productLimit={productLimit}
            onChangeSelectProduct={onChangeSelectProduct}
            setSelectedProducts={setSelectedProducts}
          />
        </Box>
        <Divider
          orientation={stackVertically ? "horizontal" : "vertical"}
          flexItem
        />
        <Box flex={1}>
          <ProductSelectionModalSelectedProductsDnD
            selectedProducts={selectedProducts}
            changedProducts={changedProducts as unknown as Product[]}
            setSelectedProducts={setSelectedProducts}
            handleRemoveSelectedProduct={handleRemoveSelectedProduct}
            handleClearAll={handleClearAllSelectedProducts}
            scenarioDetails={scenarioDetails}
          />
        </Box>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="center" px={2} pt={2}>
        <Stack direction="row" spacing={2} margin="auto">
          <Button variant="outlined" color="primary" onClick={closeModal}>
            {t("common:actions.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddProducts(selectedProducts);
            }}
            disabled={!selectedProducts.length}
            data-testid="apply-btn"
          >
            {t("common:actions.apply")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
