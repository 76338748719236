import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../common/Notification/showSnackbar";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useFormik } from "formik";
import UploadFiles from "../../common/UploadFiles/UploadFiles";
import { AppConstant } from "constants/AppConstant";
import { useCreateIssueIssuesPost } from "orval/generated/endpoint";
import { AxiosError } from "axios";
import { BodyCreateIssueIssuesPost } from "orval/generated/models";
import { useCallback, useState } from "react";
import ConfirmationModal from "components/common/ConfirmationModal/ConfirmationModal";
import Modal from "components/common/Modal/Modal";
import { useModal } from "components/common/Modal";

type FormValues = {
  title: string;
  description: string;
  sendCopy: boolean;
  attachments: File[];
};

export default function ReportIssueModalContent() {
  const { t } = useTranslation("common");
  const showSnackbar = useSnackbar();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const { closeModal } = useModal();

  const { mutate: createIssue, isPending: createIssueIsPending } =
    useCreateIssueIssuesPost({
      mutation: {
        onSuccess: () => {
          showSnackbar(t("reportIssue.successMessage"), "success");
          closeModal();
        },
        onError: (createIssueError: AxiosError) => {
          console.warn(createIssueError);
          showSnackbar(t("reportIssue.errorMessage"), "error");
        },
      },
    });

  const validationSchema = yup.object({
    title: yup
      .string()
      .required(t("reportIssue.titleFieldRequired"))
      .min(3, t("reportIssue.titleFieldMinimum"))
      .max(50, t("reportIssue.titleFieldMaximum")),

    description: yup
      .string()
      .required(t("reportIssue.descriptionFieldRequired"))
      .min(3, t("reportIssue.descriptionFieldMinimum"))
      .max(255, t("reportIssue.descriptionFieldMaximum")),
    attachments: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileFormat",
            t("common:validation.invalidFileType"),
            (value) =>
              value instanceof File &&
              value.size <= 5 * 1024 * 1024 &&
              [
                AppConstant.fileMIMETypes.csv,
                AppConstant.fileMIMETypes.docx,
                AppConstant.fileMIMETypes.jpeg,
                AppConstant.fileMIMETypes.png,
                AppConstant.fileMIMETypes.xlsx,
              ].includes(value.type),
          ),
      ),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      description: "",
      title: "",
      sendCopy: true,
      attachments: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData: BodyCreateIssueIssuesPost = {
        attachments: values.attachments,
        description: values.description,
        send_copy: values.sendCopy,
        title: values.title,
      };

      createIssue({ data: formData });
    },
  });

  const handleCancelReportIssue = useCallback(() => {
    !formik.dirty ? closeModal() : setConfirmationModalIsOpen(true);
  }, [formik.dirty, closeModal]);

  return (
    <Box component="form" width="600px" p={2} onSubmit={formik.handleSubmit}>
      <Box width="100%" display="flex" justifyContent="end">
        <IconButton
          onClick={handleCancelReportIssue}
          data-testid="close-modal-btn"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box px={2}>
        <Box width="100%" display="flex" justifyContent="center" mb={4}>
          <Typography variant="h3" fontWeight={700}>
            {t("reportIssue.modalTitle")}
          </Typography>
        </Box>

        <Box maxHeight="75vh" overflow="scroll" px={1}>
          <Grid container direction="column" rowGap={2}>
            <Typography variant="h6" fontWeight={500}>
              {t("reportIssue.detailsTitle")}
            </Typography>
            <TextField
              required
              name="title"
              label={t("reportIssue.titleFieldLabel")}
              placeholder={t("reportIssue.titleFieldPlaceholder")}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title ? formik.errors.title : null}
            />
            <TextField
              required
              multiline
              rows={3}
              name="description"
              label={t("reportIssue.descriptionFieldLabel")}
              placeholder={t("reportIssue.descriptionFieldPlaceholder")}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description ? formik.errors.description : null
              }
            />
            <Box>
              <Typography variant="h6" fontWeight={500}>
                {t("reportIssue.uploadAttachmentLabel")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#6A6A6A", opacity: 0.9 }}
              >
                {t("reportIssue.uploadAttachmentDescription")}
              </Typography>
            </Box>

            <Box data-testid="upload-file">
              <UploadFiles
                files={formik.values.attachments}
                setFiles={(value: File[]) => {
                  formik.setFieldValue("attachments", value);
                }}
                maxFilesNumber={3}
                maxFileSize={5}
                validMIMEFileTypes={[
                  AppConstant.fileMIMETypes.csv,
                  AppConstant.fileMIMETypes.docx,
                  AppConstant.fileMIMETypes.jpeg,
                  AppConstant.fileMIMETypes.png,
                  AppConstant.fileMIMETypes.xlsx,
                ]}
              />
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  name="sendCopy"
                  checked={formik.values.sendCopy}
                  onChange={formik.handleChange}
                />
              }
              label={t("reportIssue.sendCopyLabel")}
            />
          </Grid>
        </Box>
      </Box>

      <Grid container width="100%" justifyContent="center" mt={4} columnGap={2}>
        <Button
          variant="outlined"
          onClick={handleCancelReportIssue}
          data-testid="cancel-btn"
        >
          {t("actions.cancel")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={createIssueIsPending || !Object.keys(formik.touched).length}
          data-testid="submit-btn"
        >
          {createIssueIsPending && <CircularProgress size={10} />}
          <Box {...(createIssueIsPending ? { pl: "10px" } : {})}>
            {t("labels.submit")}
          </Box>
        </Button>
      </Grid>

      <Modal
        content={
          <ConfirmationModal
            title={`${t("reportIssue.cancelConfirmationModal.title")}`}
            description={t("reportIssue.cancelConfirmationModal.description")}
            actionTitle={t("actions.confirm")}
            confirmAction={() => {
              closeModal();
            }}
            cancelAction={() => setConfirmationModalIsOpen(false)}
          />
        }
        closeModal={() => setConfirmationModalIsOpen(false)}
        isOpen={confirmationModalIsOpen}
        navigationAwayLocked={true}
      />
    </Box>
  );
}
